import I18n from 'i18n-js'
import isoLanguages from '@cospired/i18n-iso-languages'
import isoCountries from 'i18n-iso-countries'
import localeCodes from 'locale-codes'
import _isString from 'lodash/isString'
import _uniq from 'lodash/uniq'

export const langTrOpt = { scope: 'common.languageNames' }

export const DEFAULT_LANGUAGE = 'en'

export const BCP_47_REGEX = /^[a-z]{2}-[0-9A-Z]{2,}$/

// Alphabetised list of ISO 639-1 (e.g. en) codes and BCP-47 e.g. (e.g. en-GB)
const ALLOWED_LANGUAGE_TAGS = ['no', 'fil', 'zh', 'zh-CN', 'zh-HK', 'zh-SG', 'zh-TW']

// All supported language codes
export const ALL_LANGUAGE_CODES = localeCodes.all.reduce((acc, l) => {
  if (
    l['iso639-1'] || // Only include languages from the library with a ISO-639-1 code
    ALLOWED_LANGUAGE_TAGS.includes(l.tag) // With the exception of these languages which do not have ISO 639-1 code in locale-codes
  ) {
    acc.push(l.tag.replace(/[^0-9a-z-]/gi, ''))
  }
  return acc
}, [
  // Codes not available in locale-codes or tricky to exclude using above logic
  'en-CN', // English (China)
  'en-ID', // English (Indonesia)
  'en-LK', // English (Sri Lanka)
  'en-CL', // English (Chile)
  'es-CA' // Spanish (Canada)
])
  .sort()

// ALLOWED_LANGUAGE_CODES is the list of languages that will be visible in field, filters etc.
const ALLOWED_LANGUAGE_CODES_ENV_VAR = window.__USECURE_CONFIG__.REACT_APP_ALLOWED_LANGUAGE_CODES
const ALLOWED_LANGUAGE_CODES = _isString(ALLOWED_LANGUAGE_CODES_ENV_VAR)
  ? _uniq(ALLOWED_LANGUAGE_CODES_ENV_VAR.split(',')
    .reduce((acc, code) => {
      if (ALL_LANGUAGE_CODES.includes(code)) {
        acc.push(code)
      }
      return acc
    }, []))
  : []

export const LANGUAGE_CODES = ALLOWED_LANGUAGE_CODES.length > 0 ? ALLOWED_LANGUAGE_CODES : ALL_LANGUAGE_CODES
export const BASE_LANGUAGE_CODES = _uniq(LANGUAGE_CODES.reduce((acc, code) => {
  if (BCP_47_REGEX.test(code)) {
    acc.push(code.substring(0, 2))
  } else {
    acc.push(code)
  }
  return acc
}, []))

export const TRANSLATED_LANGUAGE_CODES = [
  'en', 'es', 'fr', 'cs', 'zh', 'de', 'nl', 'it', 'en-US', 'pt', 'sv', 'no', 'fi'
]

// Generate map object of locale + I18n translated language name pairs
// This allows us to set our own language names before falling back on @cospired/i18n-iso-languages, i18n-iso-countries and the Intl API to generate names programmatically.
// Using our custom I18n.tWithoutDefaultFallback method alongside our custom returnMissingAsNull option means that language names missing a translation for the current language will return null.
// This means that we use the fallback solution and present all language names in the user's current language or the base language if it's a regional variant.
const translatedLangTrOpt = { ...langTrOpt, returnMissingAsNull: true }
const TRANSLATED_LANGUAGE_NAMES_BY_CODE = TRANSLATED_LANGUAGE_CODES.reduce((acc, locale) => {
  Object.defineProperty(acc, locale, {
    get: () => I18n.tWithoutDefaultFallback(locale, translatedLangTrOpt)
  })
  return acc
}, {})

// The inbuilt Intl class could also be used to provide language names if needed
// const languageNamesInFrench = new Intl.DisplayNames(['fr'], { type: 'language', style: 'short' });
// languageNamesInFrench.of('fr-ca') gives “français canadien”
// Holding this is reserve in case the more controlled method below doesn't work
export const LANGUAGES = LANGUAGE_CODES.map(code => {
  return {
    code,
    get name () {
      // Use existing I18n translation for language's name if present
      if (TRANSLATED_LANGUAGE_NAMES_BY_CODE[this.code]) return TRANSLATED_LANGUAGE_NAMES_BY_CODE[this.code]

      let langName
      // Get "<Language> (<Country>)" for BCP-47 code or "<Language>" ISO-639 codes`
      let currentBaseLocale = I18n.locale ?? DEFAULT_LANGUAGE
      if (BCP_47_REGEX.test(currentBaseLocale)) {
        // Use ISO-639 code for fallback methods
        currentBaseLocale = currentBaseLocale.substring(0, 2)
      }
      let langCode
      let countryCode
      if (BCP_47_REGEX.test(this.code)) {
        ([langCode, countryCode] = this.code.split('-'))
        if (TRANSLATED_LANGUAGE_NAMES_BY_CODE[langCode]) {
          // Use translated base language name for langCode e.g. en would be English
          langName = TRANSLATED_LANGUAGE_NAMES_BY_CODE[langCode]
        }
      } else {
        langCode = this.code
      }

      if (!langName) {
        langName = isoLanguages.getName(langCode, currentBaseLocale)
      }
      if (langName && countryCode) {
        const countryName = isoCountries.getName(countryCode, currentBaseLocale, { select: 'alias' })
        if (countryName) {
          return `${langName} (${countryName})`
        }
      } else if (langName) {
        return langName
      }

      // Use Intl Class as fallback
      const intlLanguageNames = new Intl.DisplayNames([currentBaseLocale], { type: 'language', style: 'short' })
      let intlLanguageName
      try {
        intlLanguageName = intlLanguageNames.of(code.toLowerCase())
      } catch (e) {
        console.error(`No language name found for ${code.toLowerCase()} in Intl.DisplayNames`, e)
      }
      return intlLanguageName || this.code
    }
  }
})

export const LANGUAGE_SELECT_OPTIONS = LANGUAGES.map((lang) => { return { value: lang.code, get label () { return lang.name } } })
export const LANGUAGE_TREE_OPTIONS = LANGUAGES.map((lang) => { return { key: lang.code, isLeaf: true, value: lang.code, get title () { return lang.name } } })

export const LANGUAGE_NAMES_BY_CODE = LANGUAGES.reduce((acc, lang) => {
  Object.defineProperty(acc, lang.code, {
    get: () => lang.name
  })
  return acc
}, {})

export const NO_LANGUAGE_VALUE = '%NO_LANGUAGE%'

const generateBaseLanguageToRegionalVariantMap = locales =>
  locales.reduce((acc, locale) => {
    const isRegional = BCP_47_REGEX.test(locale)
    const baseLocale = isRegional ? locale.substr(0, 2) : locale
    acc[baseLocale] = acc[baseLocale] ?? []
    if (isRegional) {
      acc[baseLocale].push(locale)
    }
    return acc
  }, {})

export const ALL_LANGUAGE_CODES_BASE_TO_REGION_MAP = generateBaseLanguageToRegionalVariantMap(ALL_LANGUAGE_CODES)
export const LANGUAGE_CODES_BASE_TO_REGION_MAP = generateBaseLanguageToRegionalVariantMap(LANGUAGE_CODES)
