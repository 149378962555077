import React, { useState, useCallback, useImperativeHandle, useMemo, useEffect, useRef } from 'react'
import { Modal, message, Button } from 'antd'
import styled from 'styled-components'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'

import { connect } from '../../../hocs'
import selectors from '../../../state/selectors'
import UserBreachesTable from '../../uBreach/UserBreachesTable'
import { WRITABLE_UBREACH_ACTIONS } from '../../../constants/actions'
import { ListHeader, ListHeaderPanel, ListViewActions, ErrorAlerts, SearchBar, LoadingBlock, ModalPager } from '../../../components/common'
import { processListActions, searchRecords } from '../../../helpers/listPages'
import ViewRecordCard from '../../common/ViewRecordCard'
import MarkResolvedConfirm from './MarkResolvedConfirm'
import { stripHTML } from '../../../helpers/sanitise'
import DataClassTag from '../../uBreach/DataClassTag'
import SeverityField from '../../uBreach/SeverityField'
import { sortDataClasses } from '../../../helpers/uBreachV2'
import { useHasSessionPermission } from '../../../hooks'
import { permissions } from '../../../constants/permissions'

const uBreachTrOpt = { scope: 'uBreach' }

const BreachModal = styled(Modal)`
  height: calc(100% - 80px);
  top: 50px;
  
  .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .ant-modal-content {
    height: 100%;
  }
`

const ModalScroller = styled.div`
  height: 100%;
  overflow: auto;
`

const ModalPagerPlaceholder = styled.div`
  height: 32px;
`

const getRowActions = ({ record, disabledActions, omittedActions }) => {
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', uBreachTrOpt) }
  ]

  const recordOmittedActions = [...omittedActions]
  if (record.resolved) recordOmittedActions.push('markResolved')

  return processListActions({ actions, disabledActions, omittedActions: recordOmittedActions })
}

const getSelectionActions = ({ selection, disabledActions, omittedActions }) => {
  if (selection.length === 1) return getRowActions({ record: selection[0], disabledActions, omittedActions })
  const actions = [
    { key: 'markResolved', label: I18n.t('common.markResolved', uBreachTrOpt) }
  ]

  return processListActions({ actions, disabledActions, omittedActions })
}

const BreachDetails = ({ allRecords, loading, searchText, setSearchText, selectedIds, setSelectedIds, planValid, breach, uBreachProEnabled, breachedUsers = [], breachedServices = [], refetchQueries, handleRefreshClick }) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()

  const { disabledActions, omittedActions } = useMemo(() => {
    const omittedActions = []

    if (!hasAllSessionPermissions([permissions.BREACH_RESOLUTION_CREATE]) || !uBreachProEnabled) omittedActions.push('markResolved')

    return {
      disabledActions: !planValid ? WRITABLE_UBREACH_ACTIONS : null,
      omittedActions
    }
  }, [planValid, hasAllSessionPermissions, uBreachProEnabled])

  const markResolvedConfirmRef = useRef(null)
  const openMarkResolvedConfirm = useCallback((records) => {
    if (markResolvedConfirmRef.current) {
      markResolvedConfirmRef.current.open(records)
    }
  }, [markResolvedConfirmRef])

  const onSearchChange = useCallback(event => {
    const { value } = event.target
    setSearchText(value)
  }, [setSearchText])

  const records = useMemo(() => {
    const { id: breachId } = breach || {}
    const records = allRecords.map(user => {
      const { email } = user
      const record = {
        ...user,
        key: email,
        breachId
      }

      record.actions = getRowActions({
        record,
        disabledActions,
        omittedActions
      })
      return record
    })
    return searchRecords({ records, searchText, searchKeys: ['email', 'learnerName'] })
  }, [allRecords, searchText, disabledActions, omittedActions, breach])

  const performAction = useCallback((action, ids) => {
    let actionRecord
    let actionRecords
    if (ids.length === 1) {
      actionRecord = allRecords.find(record => record.email === ids[0])
      if (actionRecord) {
        actionRecords = [actionRecord]
      }
    } else if (ids.length > 1) {
      actionRecords = allRecords.filter(record => ids.includes(record.email))
    }
    if (!actionRecord && !actionRecords) {
      message.error(I18n.t('common.actionCouldNotBePerformed'))
      return
    }

    switch (action) {
      case 'markResolved':
        openMarkResolvedConfirm(
          actionRecords.map(record => _pick(record, ['email', 'learnerId', 'breachName']))
        )
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [allRecords, openMarkResolvedConfirm])

  const listActions = useMemo(() =>
    getSelectionActions({
      selection: allRecords.filter(record => selectedIds.includes(record.key)),
      disabledActions,
      omittedActions
    }),
  [selectedIds, allRecords, disabledActions, omittedActions])

  return (
    <ModalScroller>
      <ViewRecordCard>
        <div className='view-record__body'>
          <div className='view-record__header'>
            <div style={{ display: 'flex', gap: 10, justifyContent: 'space-between', width: '100%' }}>
              <h1>{loading || !breach ? I18n.t('common.loading') : breach.title}</h1>
              <SeverityField severity={breach?.category} />
            </div>
          </div>
          <div>
            <div style={{ marginBottom: 10 }}>{stripHTML(breach?.description)}</div>
            <div>
              {sortDataClasses(breach?.dataClasses || []).map(dataClass => (
                <DataClassTag
                  {...{ dataClass }}
                  key={dataClass}
                />
              ))}
            </div>
          </div>
          <ListHeader>
            <ListHeaderPanel align='left' />
            <ListHeaderPanel align='right'>
              <Button icon={loading ? 'loading' : 'reload'} ghost type='primary' disabled={loading} onClick={handleRefreshClick}>{I18n.t('common.refresh')}</Button>
            </ListHeaderPanel>
          </ListHeader>
          <ListHeader>
            <ListHeaderPanel align='left'>
              {I18n.t('totalUserBreachCount', { ...uBreachTrOpt, count: allRecords.length })}
            </ListHeaderPanel>
            <ListHeaderPanel align='right'>
              <SearchBar
                placeholder={I18n.t('common.searchUserBreaches', uBreachTrOpt)}
                value={searchText}
                allowClear
                onChange={onSearchChange}
              />
              <ListViewActions
                actions={listActions}
                performAction={performAction}
                selectedIds={selectedIds}
              />
            </ListHeaderPanel>
          </ListHeader>
          {
            records && !loading && (
              <UserBreachesTable
                updateSelectedIds={setSelectedIds}
                {...{
                  performAction,
                  userBreaches: records,
                  selectedIds,
                  breachedServices,
                  breachedUsers
                }}
              />
            )
          }
        </div>
      </ViewRecordCard>
      <MarkResolvedConfirm ref={markResolvedConfirmRef} {...{ refetchQueries }} type='breachedUsers' />
    </ModalScroller>
  )
}

const BreachDetailsModal = React.forwardRef(({ planValid, uBreachProEnabled, breachedUsers = [], breachedServices = [], error, refetchQueries, handleRefreshClick }, ref) => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [breach, setBreach] = useState(null)
  const [pager, setPager] = useState({})
  const [domain, setDomain] = useState(null)
  const [breachName, setBreachName] = useState(null)
  const [allRecords, setAllRecords] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedIds, setSelectedIds] = useState([])

  useEffect(() => {
    if (breachName) {
      const breach = breachedServices.find(b => b.name === breachName)
      const userBreaches = breachedUsers.reduce((acc, user) => {
        const { breaches, email, domain: userDomain, learnerId, firstName, lastName } = user
        if (domain && userDomain !== domain) return acc
        const breach = breaches.find(b => b.name === breachName)
        if (breach) {
          acc.push({
            email,
            domain: userDomain,
            learnerId,
            learnerName: `${firstName || ''} ${lastName || ''}`.trim(),
            breachName: breach.name,
            breachTitle: breach.title,
            breachedData: breach.breachedData,
            resolved: breach.resolved,
            hasExposedInfoData: breach.hasExposedInfoData
          })
        }
        return acc
      }, [])

      setBreach(breach)
      setAllRecords(userBreaches)
    }
  }, [breachedServices, breachedUsers, setBreach, setAllRecords, breachName, domain])

  useImperativeHandle(ref, () => ({
    open: async ({ record: { id: breachName }, domain, pager }) => {
      setLoading(true)
      setVisible(true)

      setDomain(domain)
      setBreachName(breachName)
      setPager(pager)

      setLoading(false)
    }
  }))

  const closeModal = useCallback(() => setVisible(false), [])
  const afterClose = () => {
    setVisible(false)
    setLoading(false)
    setBreach(null)
    setDomain(null)
    setBreachName(null)
    setAllRecords([])
    setSearchText('')
    setSelectedIds([])
    setPager({})
  }

  return (
    <BreachModal
      visible={visible} onCancel={closeModal} destroyOnClose footer={null}
      afterClose={afterClose}
      width='90%'
      bodyStyle={{ paddingTop: 5, height: 'calc(100% - 60px)' }}
      title={loading ? <ModalPagerPlaceholder /> : <ModalPager {...pager} />}
    >
      <LoadingBlock loading={loading} fullScreen={false} />
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('errors.yourBreachesCouldNotBeLoaded', uBreachTrOpt)} />
          : (
            <BreachDetails
              {...{
                allRecords,
                breach,
                searchText,
                setSearchText,
                selectedIds,
                setSelectedIds,
                planValid,
                loading,
                uBreachProEnabled,
                refetchQueries,
                handleRefreshClick
              }}
            />
          )
      }
    </BreachModal>
  )
})

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['planValid']),
    ..._pick(selectors.settings.get(state), ['uBreachProEnabled'])
  })
)(BreachDetailsModal)
