import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import I18n from 'i18n-js'
import { Tabs, Card, Switch, Tooltip, Button } from 'antd'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import _pick from 'lodash/pick'
import _sampleSize from 'lodash/sampleSize'
import _times from 'lodash/times'
import _uniq from 'lodash/uniq'

import useLocalStorage from '../../hooks/useLocalStorage'
import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { ContentWrap, ListHeader, ListHeaderPanel, LoadingBlock, PopUpContainer as BasePopUpContainer } from '../../components/common'
import BreachesView from '../../components/uBreach/BreachesView'
import UserBreachesView from '../../components/uBreach/UserBreachesView'
import DomainsView from '../../components/uBreach/DomainsView'
import DashboardStatistic from '../../components/Dashboard/DashboardStatistic'
import GridPageHeader from '../../components/common/GridPageHeader'
import TooltipIcon from '../../components/common/TooltipIcon'
import { formatDate } from '../../helpers/datetime'
import { GET_BREACHES_DATA } from '../../components/Queries/uBreach'
import UpgradeUBreachProAlert from '../../components/uBreach/upgradeUBreachProAlert'
import ExportServiceDownloadButton from '../../components/common/ExportServiceDownloadButton'
import CreateDomainScanReportModal from '../../components/Modals/CreateDomainScanReportModal'
import UBreachProUpgradeCTAOverlay from '../../components/uBreach/UBreachProUpgradeCTAOverlay'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const SHOW_UBREACH_PRO_UPGRADE_BANNER = window.__USECURE_CONFIG__.REACT_APP_SHOW_UBREACH_PRO_UPGRADE_BANNER === 'true'
// This is only needed before uBreach Pro's full launch to avoid premature access to the upgrade process
const DISABLE_UBREACH_PRO_UPGRADE = window.__USECURE_CONFIG__.REACT_APP_DISABLE_UBREACH_PRO_UPGRADE === 'true'

const { TabPane } = Tabs
const trOpt = { scope: 'uBreach' }

const getResolvedPercentageAndColour = (totalResolvedUserBreaches, totalUserBreaches) => {
  const resolvedPercentage = Math.round(totalResolvedUserBreaches * 100 / totalUserBreaches)

  // JG: this is the reverse of the logic in DashboardStatistic
  let resolvedGaugeColor = '#5EB77E'
  if (resolvedPercentage <= 25) {
    resolvedGaugeColor = '#f75959'
  } else if (resolvedPercentage > 25 && resolvedPercentage < 75) {
    resolvedGaugeColor = '#F8DC4A'
  }

  return { resolvedPercentage, resolvedGaugeColor }
}

// The upgrade CTA version of the Domains uses a dummy domain rather than the company's domain to spare a query to retrieve it. It's barely visible so it seems like a wasted request.
const DUMMY_DOMAIN = 'mycompany.com'
const DUMMY_RESOLVED_DATA = {
  totalResolvedUserBreaches: 25,
  totalUserBreaches: 100,
  ...getResolvedPercentageAndColour(25, 100)
}

// TODO: We recreate this for each report, could be standard component
const UBreachReportGrid = styled.div`
  display: grid;
  grid-template-areas:
          "topbar topbar topbar topbar"
          "total-breaches  services-appeared-in breached resolved-user-breaches"
          "table table table table";

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto 260px auto;
  grid-gap: 25px;
`

// TODO: We recreate this for each report, should be standard component
const UBreachItem = styled.div`
  background-color: white;
  padding: 30px 40px;
  position: relative;
`
const ExcludeNonSystemUsersToggle = ({ excludeNonSystemUsers, handleChange, getPopupContainer }) => (
  <>
    <Switch checked={excludeNonSystemUsers} onChange={handleChange} />
    <span style={{ marginLeft: 10 }}>
      {I18n.t('uBreach.excludeNonSystemUsers')}
      &nbsp;
      <Tooltip title={I18n.t('nonSystemUserTooltip', trOpt)} {...{ getPopupContainer }}>
        <span><TooltipIcon /></span>
      </Tooltip>
    </span>
  </>
)

// This has position:relative to avoid changing ContentWrap. An alternative solution would be enclosure the children in this view's ContentWrap within a postive:relative div.
const PopUpContainer = styled(BasePopUpContainer)`
  position: relative;
`

// Prevents UBreachProUpgradeCTAOverlay from obscuring the tabs
const TabContainer = styled.div`
  position: relative;
`

const UBreach = ({ companyId, userId, uBreachProEnabled, uBreachProSettings, excludeInactiveUsersInReports, accountType, parentCompanyId, billingType, distributorUBreachProEnabled, enableCreateDomainScan, planName }) => {
  const [tabKey, setTabKey] = useState('breaches')
  const [excludeNonSystemUsers, setExcludeNonSystemUsers] = useState(false)

  const { hasAllSessionPermissions } = useHasSessionPermission()

  const onTabChange = useCallback(tabKey => setTabKey(tabKey), [setTabKey])

  const popupContainerRef = useRef(null)
  const createDomainScanReportModalRef = useRef(null)

  const getPopupContainer = useCallback(() => {
    const { current: popupContainer } = popupContainerRef
    return popupContainer || document.body
  }, [popupContainerRef])

  const storageId = `uBreach|tabKey|${companyId}|${userId}`
  const { updateLocalStorage, updateFromLocalStorage } = useLocalStorage({ storageId })
  const isDirectMsp = useMemo(() => accountType === 'msp' && !parentCompanyId, [accountType, parentCompanyId])
  const isDirectTenant = useMemo(() => accountType === 'tenant' && !parentCompanyId, [accountType, parentCompanyId])
  const isBillingEnabled = useMemo(() => billingType === 'auto', [billingType])

  const {
    data: {
      breachesData: {
        breachedUsers: allBreachedUsers = [],
        breachedServices: allBreachedServices = [],
        learnerCounts = {}
      } = {}
    } = {}, error, loading, refetch
  } = useQuery(GET_BREACHES_DATA)

  const handleRefreshClick = useCallback(() => {
    refetch()
  }, [refetch])

  const refetchQueries = useMemo(() => [
    { query: GET_BREACHES_DATA }
  ], [])

  useEffect(() => {
    if (!updateFromLocalStorage) return

    updateFromLocalStorage({
      tabKey: setTabKey,
      excludeNonSystemUsers: setExcludeNonSystemUsers
    })
  }, [updateFromLocalStorage, storageId])

  useEffect(() => {
    if (!updateLocalStorage) return
    updateLocalStorage({
      tabKey,
      excludeNonSystemUsers
    })
  }, [updateLocalStorage, tabKey, excludeNonSystemUsers])

  const breachedUsers = useMemo(() => {
    if (!allBreachedUsers.length) return []

    let filteredBreachedUsers = allBreachedUsers

    // Filter out non-system users, if requested
    if (excludeNonSystemUsers) {
      filteredBreachedUsers = filteredBreachedUsers.filter(({ learnerId }) => learnerId)
    }

    // Filter out inactive users, if requested
    if (excludeInactiveUsersInReports) {
      filteredBreachedUsers = filteredBreachedUsers.filter(({ learnerId, inactive }) => learnerId ? !inactive : true)
    }

    return filteredBreachedUsers
  }, [allBreachedUsers, excludeNonSystemUsers, excludeInactiveUsersInReports])

  const breachedServices = useMemo(() => {
    // Filter out services that have no breaches
    const breachedServiceNames = _uniq(breachedUsers.reduce((acc, { breaches }) => [...acc, ...breaches.map(({ name }) => name)], []))
    return allBreachedServices.filter(({ name }) => breachedServiceNames.includes(name))
  }, [allBreachedServices, breachedUsers])

  const { upgradeAllowed, selfService } = useMemo(() => ({
    upgradeAllowed: (
      hasAllSessionPermissions([permissions.BILLING_UPDATE]) &&
      !DISABLE_UBREACH_PRO_UPGRADE &&
      !uBreachProEnabled &&
      (
        // Direct MSPs or Distributor MSPs whose disti has uBreach Pro enabled
        (
          accountType === 'msp' &&
          (
            isDirectMsp ||
            distributorUBreachProEnabled
          )
        ) ||
        // Direct, paid customers
        (isDirectTenant && planName !== 'freeTrial')
      )
    ),
    // Direct, billed MSPs can self upgrade as we are in control of their billing
    selfService: isDirectMsp && isBillingEnabled
  }), [uBreachProEnabled, isDirectMsp, isDirectTenant, distributorUBreachProEnabled, accountType, planName, isBillingEnabled, hasAllSessionPermissions])

  const sectionData = useMemo(() => {
    const { latestBreachDate, latestBreachedService } = breachedServices.reduce((acc, breachedService) => {
      if (!acc.latestBreachDate || (moment(breachedService.breachDate) > moment(acc.latestBreachDate))) {
        acc.latestBreachDate = breachedService.breachDate
        acc.latestBreachedService = breachedService.title
      }
      return acc
    }, {
      latestBreachDate: null,
      latestBreachedService: null
    })

    const {
      totalBreaches
    } = breachedUsers.reduce((acc, { breaches, learnerId }) => {
      acc.totalBreaches += breaches.length
      if (learnerId) acc.totalBreachedUsers += 1
      return acc
    }, {
      totalBreaches: 0
    })

    const totalBreachedUsers = breachedUsers.filter(({ learnerId }) => learnerId).length
    const totalUsers = excludeInactiveUsersInReports ? learnerCounts.active : learnerCounts.all

    const { totalUserBreaches, totalResolvedUserBreaches } = breachedUsers.reduce((acc, { breaches }) => {
      acc.totalUserBreaches += breaches.length
      acc.totalResolvedUserBreaches += breaches.filter(({ resolved }) => resolved).length
      return acc
    }, {
      totalUserBreaches: 0,
      totalResolvedUserBreaches: 0
    })

    const { resolvedPercentage, resolvedGaugeColor } = getResolvedPercentageAndColour(totalResolvedUserBreaches, totalUserBreaches)

    const sectionData = [
      {
        title: I18n.t('totalBreaches', trOpt),
        gridArea: 'total-breaches',
        link: '/uPhish/simulations',
        value: totalBreaches,
        extraTitle: I18n.t('servicesAppearedIn', trOpt),
        extraData: breachedServices.length,
        hoverText: I18n.t('aTotalOfBreaches', { count: totalBreaches, services: I18n.t('services', { count: breachedServices.length, ...trOpt }), ...trOpt }),
        isValue: true
      },
      {
        title: I18n.t('latestBreach', trOpt),
        gridArea: 'services-appeared-in',
        link: '/uPhish/simulations',
        value: (
          <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
            {latestBreachDate ? formatDate(latestBreachDate) : I18n.t('uBreach.noBreachesFound')}
          </p>
        ),
        extraTitle: I18n.t('latestBreachedService', trOpt),
        extraData: latestBreachedService || I18n.t('common.na'),
        hoverText: latestBreachDate ? I18n.t('yourMostRecentBreach', { lastBreachDate: moment(latestBreachDate).fromNow(), ...trOpt }) : null,
        isValue: true
      },
      {
        title: I18n.t('breached', trOpt),
        gridArea: 'breached',
        link: '/uPhish/simulations',
        percentage: Math.round(totalBreachedUsers * 100 / totalUsers),
        isGauge: true,
        extraTitle: I18n.t('totalUsersBreached', trOpt),
        extraData: totalBreachedUsers,
        hoverText: I18n.t('aTotalOfUsers', { count: totalBreachedUsers, usersBreached: I18n.t('usersBreached', { count: totalUsers, ...trOpt }), ...trOpt })
      }
    ]
    if (uBreachProEnabled || upgradeAllowed) {
      let resolvedBreachesSection = {
        title: I18n.t('resolvedUserBreaches', trOpt),
        gridArea: 'resolved-user-breaches',
        percentage: resolvedPercentage,
        color: resolvedGaugeColor,
        isGauge: true,
        extraTitle: I18n.t('totalUserBreaches', trOpt),
        extraData: totalUserBreaches,
        hoverText: I18n.t('aTotalResolvedUserBreaches', { count: totalResolvedUserBreaches, totalUserBreaches, ...trOpt })
      }
      if (!uBreachProEnabled) {
        // Use dummy values to be displayed behind the upgrade CTA overlay
        resolvedBreachesSection = {
          ...resolvedBreachesSection,
          showUpgradeOverlay: true,
          percentage: DUMMY_RESOLVED_DATA.resolvedPercentage,
          color: DUMMY_RESOLVED_DATA.resolvedGaugeColor,
          extraData: DUMMY_RESOLVED_DATA.totalUserBreaches,
          hoverText: I18n.t('aTotalResolvedUserBreaches', { count: DUMMY_RESOLVED_DATA.totalResolvedUserBreaches, totalUserBreaches: DUMMY_RESOLVED_DATA.totalUserBreaches, ...trOpt })
        }
      }
      sectionData.push(resolvedBreachesSection)
    }
    return sectionData
  }, [breachedUsers, breachedServices, excludeInactiveUsersInReports, learnerCounts, uBreachProEnabled, upgradeAllowed])

  const tabs = useMemo(() => {
    const tabs = []
    tabs.push({
      tab: I18n.t('breaches', trOpt),
      key: 'breaches',
      content: (
        <BreachesView {...{ breachedUsers, breachedServices, error, loading, refetchQueries, handleRefreshClick }} />
      )
    })
    if ((uBreachProEnabled && uBreachProSettings?.breachDomains && uBreachProSettings.breachDomains.length > 0) || upgradeAllowed) {
      let domainsTabProps = { breachedUsers, breachedServices }
      if (upgradeAllowed) {
        // dummy data for upgrade CTA overlay
        // The users and breached services therein won't be accessible so the content below is auto generated junk that DomainsView can work with.
        const dummyBreachedServices = _sampleSize(breachedServices, 10)
        domainsTabProps = {
          dummyMode: true,
          dummyDomains: [DUMMY_DOMAIN],
          breachedServices: dummyBreachedServices,
          breachedUsers: _times(40, index => ({
            email: `sample.user${index}@${DUMMY_DOMAIN}`,
            domain: DUMMY_DOMAIN,
            firstName: 'Sample',
            lastName: `User${index}`,
            inactive: false,
            breaches: _sampleSize(dummyBreachedServices, 3).map(({ name, title }) => ({
              name,
              title,
              resolved: index % 4 === 0
            }))
          }))
        }
      }
      tabs.push({
        tab: I18n.t('domains', trOpt),
        key: 'domains',
        content: (
          <>
            {upgradeAllowed && <UBreachProUpgradeCTAOverlay {...{ selfService }} showDescription />}
            <DomainsView {...{ error, loading, refetchQueries, handleRefreshClick }} {...domainsTabProps} />
          </>
        )
      })
    }
    tabs.push({
      tab: I18n.t('users', trOpt),
      key: 'users',
      content: <UserBreachesView {...{ breachedUsers, breachedServices, error, loading, refetchQueries, handleRefreshClick, getPopupContainer }} />
    })
    return tabs
  }, [uBreachProSettings, uBreachProEnabled, breachedUsers, breachedServices, error, loading, refetchQueries, handleRefreshClick, getPopupContainer, upgradeAllowed, selfService])

  // JG - <Switch> returns a few extra props that we don't need, we can't just pass everything to setExcludeNonSystemUsers without it complaining
  const handleExcludeNonSystemUsersChange = useCallback((value) => { setExcludeNonSystemUsers(value) }, [setExcludeNonSystemUsers])

  const additionalHeaderToggles = useMemo(() => {
    if (uBreachProEnabled) {
      return [
        <ExcludeNonSystemUsersToggle
          key='excludeNonSystemUsers'
          excludeNonSystemUsers={excludeNonSystemUsers} handleChange={handleExcludeNonSystemUsersChange}
          {...{ getPopupContainer }}
        />
      ]
    }
    return []
  }, [uBreachProEnabled, excludeNonSystemUsers, handleExcludeNonSystemUsersChange, getPopupContainer])

  const openDomainScanReportModal = useCallback(() => {
    createDomainScanReportModalRef.current.open()
  }, [createDomainScanReportModalRef])

  const pageTitle = useMemo(() => (
    <span>
      {I18n.t(uBreachProEnabled ? 'common.uBreachPro' : 'common.uBreach')}
      {hasAllSessionPermissions([permissions.DOMAIN_SCAN_REPORT_CREATE]) && enableCreateDomainScan && (
        <Button type='primary' onClick={openDomainScanReportModal} style={{ marginLeft: 20 }}>
          {I18n.t('uBreach.createDomainScanReport')}
        </Button>
      )}
    </span>
  ), [uBreachProEnabled, enableCreateDomainScan, openDomainScanReportModal, hasAllSessionPermissions])

  return (
    <>
      {enableCreateDomainScan && <CreateDomainScanReportModal ref={createDomainScanReportModalRef} />}
      {SHOW_UBREACH_PRO_UPGRADE_BANNER && upgradeAllowed && <UpgradeUBreachProAlert handleRefreshClick={handleRefreshClick} selfService={isDirectMsp && isBillingEnabled} />}
      <ContentWrap>
        <PopUpContainer ref={popupContainerRef} />
        {loading && <LoadingBlock fullscreen loading={loading} />}
        <UBreachReportGrid>
          <GridPageHeader
            title={pageTitle}
            additionalToggles={additionalHeaderToggles}
            id='uBreach-report-header'
            showExcludeInactiveToggle
          />
          {sectionData.map((item, index) => {
            return (
              <UBreachItem key={index} style={{ gridArea: `${item.gridArea}` }}>
                {item.showUpgradeOverlay && <UBreachProUpgradeCTAOverlay {...{ selfService }} />}
                <DashboardStatistic isValue={item.isValue} hoverText={item.hoverText} title={item.title} link={item.link} value={item.value} percentage={item.percentage} percentageChange={item.percentageChange} percentageDescription={item.percentageDescription} extraTitle={item.extraTitle} extraData={item.extraData} isGauge={item.isGauge} {...{ getPopupContainer }} color={item.color} />
              </UBreachItem>
            )
          })}
        </UBreachReportGrid>
        <Card>
          <ListHeader align='right'>
            <ListHeaderPanel align='right'>
              {hasAllSessionPermissions([permissions.BREACH_REPORT]) && (
                <ExportServiceDownloadButton
                  icon='download'
                  fileName='breaches{{ts}}.xlsx'
                  fileType='xlsx'
                  jobData={{ reportType: 'uBreachExport', excludeInactiveUsers: excludeInactiveUsersInReports }}
                >
                  {I18n.t('downloadBreachExport', trOpt)}
                </ExportServiceDownloadButton>
              )}
            </ListHeaderPanel>
          </ListHeader>
          <Tabs activeKey={tabKey} onChange={onTabChange}>
            {
              tabs.map(({ tab, key, content }) => (
                <TabPane key={key} tab={tab}>
                  <TabContainer>
                    {content}
                  </TabContainer>
                </TabPane>
              ))
            }
          </Tabs>
        </Card>
      </ContentWrap>
    </>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.session.get(state), ['companyId', 'userId', 'accountType', 'parentCompanyId', 'distributorUBreachProEnabled', 'billingType', 'planName']),
    ..._pick(selectors.settings.get(state), ['uBreachProEnabled', 'uBreachProSettings', 'enableCreateDomainScan']),
    ..._pick(selectors.view.get(state), ['excludeInactiveUsersInReports'])
  })
)(UBreach)
