/* global localStorage */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _get from 'lodash/get'
import styled, { withTheme, css } from 'styled-components'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import _startsWith from 'lodash/startsWith'

import { PAGE_X_PAD_DESKTOP_REM, PAGE_X_PAD_MOBILE_REM, BREAKPOINT, MIN_BREAKPOINT } from '../../constants/style'
import { withConsumer, withAppTitleAndIcons, withLearnerThemeDataQuery } from '../../hocs'
import { FadeAnimation, TransitionWithAnimation } from './common'
import { FontAwesomeIcon, LanguageDropdown as LanguageDropdownComponent } from '../common'
import {
  CONTENT_TYPE_COMPONENTS,
  LearnerCourseIntro,
  LearnerCourseRetry,
  LearnerCourseFinished,
  LearnerCourseError,
  LearnerCourseHolding,
  LearnerCourseDisabled
} from './'
import { GET_LEARNER_COURSE } from '../Queries/LearnerCourses'
import { getBrowserLocale } from '../../helpers/locale'
import { DEFAULT_LANGUAGE, LANGUAGE_CODES } from '../../constants/languages'
import CoursePreviewBannerAndKeepAlive from './CoursePreviewBannerAndKeepAlive'
import RedirectToPortalButton from '../EndUserPortal/RedirectToPortalButton'
import { getErrorStrings } from '../../helpers'
import { ULEARN_COMPLETED_SLIDES_STORAGE_PREFIX, ULEARN_VIMEO_STORAGE_PREFIX } from '../../constants/courses'
import { getEndUserSessionType } from '../../helpers/endUserSession'
import { captureSentryError } from '../../helpers/sentry'
import { identifyUnauthenticatedLearnerWithPosthog } from '../../helpers/posthog'

const trOpt = { scope: 'learnerCourse.learnerCourse' }

const TITLE_HEIGHT_DESKTOP_REM = 3.5
const TITLE_DROPDOWN_BREAKPOINT = '535px'
const END_USER_PORTAL = window.__USECURE_CONFIG__.REACT_APP_END_USER_PORTAL === 'true'
const LanguageDropdown = styled(LanguageDropdownComponent)`
  display: ${({ visible }) => visible ? 'inline-block' : 'none'};
`

export const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${props => props.background || '#eeeeee'};
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: ${BREAKPOINT}) {
    font-size: .85em;
  }
`

export const TitleWrap = styled.div`
  height: auto;
  padding-bottom: 0.7rem;
  z-index: 500;

  @media (min-width: ${BREAKPOINT}) {
    height: ${TITLE_HEIGHT_DESKTOP_REM}rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.07);
    padding-bottom: 0;
  }
  ${({ preview }) => preview ? css`
  @media (max-width: ${BREAKPOINT}) {
    padding-bottom 0;
  }
  ` : ''}
  color: ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.nav};

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
const _TitleLogo = ({ loading = false, className }) => (
  <div {...{ className }}>
    {
      loading
        ? <div className='title-logo-placeholder' />
        : <div className='title-logo-image' />
    }
  </div>
)
export const TitleLogo = styled(_TitleLogo)`
  .title-logo-image {
    background-image: url('${({ theme }) => theme.appThemeLogo}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 3.3em;
    width: 6em;
    position: relative;
    @media (min-width: ${BREAKPOINT}) {
      left: ${PAGE_X_PAD_DESKTOP_REM}rem;
    }
    left:${PAGE_X_PAD_MOBILE_REM}rem;
  }

  .title-logo-placeholder {
    height: 1.5em;
  }
`

const CourseIcon = styled(FontAwesomeIcon)`
  margin-right: .4em;
`

export const CourseTitle = styled.div`
  @media (max-width: ${BREAKPOINT}) {
    padding: 1rem 1rem 0.2em 1rem;
    text-align: center;
  }

  @media (min-width: ${MIN_BREAKPOINT}) {
    padding-right: 4rem;
  }

  position: relative;
  top: 0.3rem;
  left: 0;
  /* color: #403f3f; */
  color: ${({ theme }) => theme.secondary};
  right: 0;
  font-weight: 500;
  font-size: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (min-width: ${MIN_BREAKPOINT}) {
    justify-content: flex-end;
  }

  &> * {
    margin-bottom: 5px;
  }
`

const CurrentPage = styled.div`
  display: inline-block;
  position: relative;
  font-weight: 500;
  font-size: 1em;
  color: #929292;
  margin-bottom: 0px;
`

export const MainWrap = styled.div`
  position: absolute;
  overflow: ${props => props.useOverflow ? 'auto !important' : 'initial'};
  @media (max-width: ${TITLE_DROPDOWN_BREAKPOINT}) {
    top: ${({ preview }) => preview ? 12.5 : 7.4}rem;
  }
  @media (min-width: ${TITLE_DROPDOWN_BREAKPOINT}) and (max-width: ${BREAKPOINT}) {
    top: ${({ preview }) => preview ? 8.7 : TITLE_HEIGHT_DESKTOP_REM}rem;
  }
  @media (min-width: ${BREAKPOINT}) {
    top: ${TITLE_HEIGHT_DESKTOP_REM}rem;
  }
  bottom: 0;
  left: 0;
  right: 0;
`

const TitleAndIconDispatcher = compose(
  withConsumer,
  withAppTitleAndIcons({ noAuth: true }),
  withLearnerThemeDataQuery({
    skipQuery: (learnerId, { preview }) => preview || !learnerId
  })
)(
  () => null
)

class LearnerCourse extends Component {
  constructor (props) {
    super(props)

    this.slideRef = React.createRef()

    this.state = {
      initialised: false,
      loading: true,
      contentIndex: 0,
      lastContentIndex: -1,
      transitioning: false,
      gaStarted: false,
      finishDate: null,
      id: null,
      status: 'started',
      locale: null,
      companyId: null,
      learnerId: null,
      learnerName: null,
      courseId: null,
      courseTitle: null,
      courseIcon: null,
      content: [],
      answers: [],
      retries: [],
      score: null,
      settings: {
        minimumPassScoreEnabled: false,
        minimumPassScore: 80,
        courseMaterialWaitSeconds: 5,
        endUserPortalEnabled: false,
        forceCourseVideoSubtitles: false,
        forceCourseVimeoPlayer: false
      },
      availableLanguages: [DEFAULT_LANGUAGE],
      endUserSessionType: 'none',
      errors: [],
      errorType: null,
      loadTime: null,
      completedSlideIds: []
    }

    this.advanceSlideOnSpacePress = this.advanceSlideOnSpacePress.bind(this)
    this.canProgress = this.canProgress.bind(this)
    this.finish = this.finish.bind(this)
    this.goToNextSlide = this.goToNextSlide.bind(this)
    this.goToPrevSlide = this.goToPrevSlide.bind(this)
    this.goToSlide = this.goToSlide.bind(this)
    this.goToSlideById = this.goToSlideById.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.handleLocaleChange = this.handleLocaleChange.bind(this)
    this.retry = this.retry.bind(this)
    this.start = this.start.bind(this)
    this.update = this.update.bind(this)
    this.updateStatus = this.updateStatus.bind(this)
    this.updateSettings = this.updateSettings.bind(this)
    this.updateCourseFromSubscription = this.updateCourseFromSubscription.bind(this)
    this.setSlideCompleted = this.setSlideCompleted.bind(this)
    this.handleError = this.handleError.bind(this)
  }

  getCompletedSlideIds (courseId) {
    const value = localStorage.getItem(`${ULEARN_COMPLETED_SLIDES_STORAGE_PREFIX}${courseId}`)

    if (value === null) return []

    try {
      return JSON.parse(value)
    } catch {
      return []
    }
  }

  // Handles initial copying of props from view to state
  static getDerivedStateFromProps (props, state) {
    const { loading, data } = props

    if (state.initialised || loading || Object.keys(data).length === 0) {
      return null
    }

    return data
  }

  async componentDidMount () {
    document.addEventListener('keydown', e => this.onKeyDown(e))

    // Set global session state learnerId which update the Sentry user for logging
    // Only required on live courses as previews will be tied to an existing admin user session
    if (!this.props.preview && this.state.learnerId) {
      this.props.setLearnerId(this.state.learnerId)
    }

    // Set UI Language from browser
    const browserLocale = getBrowserLocale()
    let locale = browserLocale
    let skipLocaleInLoad = true

    // Load preferred language from locale storage if in preview
    if (this.props.preview) {
      const previewLocale = this.props.previewLocale || localStorage.getItem('coursePreviewLocale')
      if (previewLocale) {
        locale = previewLocale
        skipLocaleInLoad = false
      }
    }
    await this.updateState({ locale, preferredLocale: browserLocale, browserLocale })

    // Initial learner course load via ad hoc query
    await this.loadLearnerCourse(skipLocaleInLoad)
  }

  // Changing the view mutation via props.update or props.executeMutate empties the data props
  // Moving the data prop to state acts a buffer to prevent the UI blanking unexpected due to lack of data
  async componentDidUpdate (prevProps, prevState) {
    const { loading, data, preview } = this.props
    const { loading: prevLoading } = prevProps
    const { id, status, locale, isGapAnalysis, learnerId } = this.state
    const { status: prevStatus, contentIndex: prevContentIndex, locale: prevLocale, learnerId: prevLearnerId, isGapAnalysis: prevIsGapAnalysis } = prevState

    // Copy view props after mutation
    if (!loading && prevLoading) {
      const stateUpdate = await this.generateStateUpdateFromCourseLoad(data)
      this.setState(stateUpdate)
    }
    if (prevStatus === 'requires_retry' && status === 'started') {
      this.setState({
        contentIndex: 0,
        lastContentIndex: prevContentIndex
      })
    }
    // Update I18n locale via global state
    if (prevLocale !== locale) {
      this.props.setLocale(locale)
    }

    // Set global session state learnerId which update the Sentry user for logging
    // Only required on live courses as previews will be tied to an existing admin user session
    if (!preview && prevLearnerId !== learnerId) {
      this.props.setLearnerId(learnerId)
    }

    // Clear completed slides and Vimeo local storage items for this course when status changes to finished
    // Gap analysis is excluded from this logic as it's a question only course
    if (!isGapAnalysis && id && prevStatus && status && prevStatus !== 'finished' && status === 'finished') {
      localStorage.removeItem(`${ULEARN_COMPLETED_SLIDES_STORAGE_PREFIX}${id}`)

      const vimeoKeyPrefix = `${ULEARN_VIMEO_STORAGE_PREFIX}${id}`
      Object.keys(localStorage).forEach(key => {
        if (_startsWith(key, vimeoKeyPrefix)) {
          localStorage.removeItem(key)
        }
      })
    }

    if (isGapAnalysis !== prevIsGapAnalysis) {
      this.props.setIsGapAnalysis(isGapAnalysis)
    }
  }

  handleLocaleChange (locale) {
    this.setState({ locale }, () => this.loadLearnerCourse())
    // Persist preview language
    if (this.props.preview) {
      localStorage.setItem('coursePreviewLocale', locale)
    }
  }

  async loadLearnerCourse (skipLocale = false) {
    const loadStart = Date.now()
    const { preview = false, fromBuilder = false } = this.props
    const { id, learnerId, courseId, locale } = this.state

    let stateUpdate = { loading: false, errors: [] }
    this.props.setLoadingVisible(true)
    try {
      const variables = {
        id,
        learnerId,
        courseId,
        preview,
        fromBuilder
      }
      if (!skipLocale) {
        variables.locale = locale
      }
      const result = await this.props.client.query({
        query: GET_LEARNER_COURSE,
        variables,
        fetchPolicy: 'no-cache'
      })
      const data = _get(result, 'data.getLearnerCourse')
      if (data) {
        stateUpdate = await this.generateStateUpdateFromCourseLoad(data)
        this.props.setTheme(data.settings || {})
        identifyUnauthenticatedLearnerWithPosthog(data)
      }
      stateUpdate.endUserSessionType = preview ? 'none' : (await getEndUserSessionType())
    } catch (e) {
      stateUpdate = {
        ...stateUpdate,
        ...this.handleError(e, { errorType: 'load', msg: 'LearnerCourse.loadLearnerCourse - ERROR', returnStateUpdate: true })
      }
    } finally {
      stateUpdate.loadTime = Date.now() - loadStart
      this.setState(stateUpdate)
      this.props.setLoadingVisible(false)
    }
  }

  handleError (e, { errorType = null, loadTime = null, msg = 'LearnerCourse - ERROR', returnStateUpdate = false } = {}) {
    captureSentryError(e, { msg })
    const stateUpdate = {
      status: 'error',
      errorType,
      errors: getErrorStrings(e),
      loadTime
    }
    if (stateUpdate.errors.length === 0) {
      stateUpdate.errors = [`${e}`]
    }
    if (returnStateUpdate) {
      return stateUpdate
    }
    this.setState(stateUpdate)
  }

  async generateStateUpdateFromCourseLoad (data, reload = false) {
    const stateUpdate = { loading: false, initialised: true, ...data }
    const availableLanguages = data.availableLanguages || []
    stateUpdate.availableLanguages = availableLanguages.filter(locale => LANGUAGE_CODES.includes(locale))

    // Go straight to the slide of the last question answered
    let { id, isGapAnalysis, courseId, status, content = [], answers = [] } = stateUpdate
    if (isGapAnalysis) {
      this.props.updateCourseId(courseId)
    }

    this.props.updateId(id)

    if (!reload) {
      const { preview, previewSlideId } = this.props
      if (preview && previewSlideId) {
        const previewSlideIndex = content.findIndex(slide => slide.id === previewSlideId)
        if (previewSlideIndex >= 0) {
          stateUpdate.contentIndex = previewSlideIndex
          status = 'started'
          await this.start()
        } else {
          // No matching slide
          stateUpdate.contentIndex = 0
        }
      } else if (this.state.status === 'requires_retry' && status === 'started') {
        // Go back for first slide on a retry
        stateUpdate.contentIndex = 0
        stateUpdate.lastContentIndex = this.state.contentIndex
      } else if (['started', 'completed'].includes(status) && answers) {
        const lastQuestionAnsweredIndex = [...content].reverse().findIndex(c => {
          if (c.type !== 'multipleChoice' || (isGapAnalysis && c.courseId !== courseId)) {
            return false
          }
          return answers.find(a => a.questionId === c.id && (isGapAnalysis ? a.courseId === courseId : true))
        })
        if (lastQuestionAnsweredIndex !== -1) {
          stateUpdate.contentIndex = content.length - lastQuestionAnsweredIndex - 1
        } else if (isGapAnalysis) {
          const firstSlideOnCourseIndex = content.findIndex(c => c.courseId === courseId)
          if (firstSlideOnCourseIndex !== -1 && !this.state.gaStarted) {
            stateUpdate.contentIndex = firstSlideOnCourseIndex
          }
        }
      }
    }
    // Always show intro slide for Gap Analysis on load
    stateUpdate.status = isGapAnalysis && !this.state.gaStarted && status !== 'finished' ? `ga_intro_${status}` : status
    stateUpdate.completedSlideIds = this.getCompletedSlideIds(id)

    return stateUpdate
  }

  async updateCourseFromSubscription (data) {
    const stateUpdate = await this.generateStateUpdateFromCourseLoad(data)
    this.setState(stateUpdate)
  }

  get mutationProps () {
    return {
      update: this.update
    }
  }

  get lastSlide () {
    return this.state.content[this.state.lastContentIndex]
  }

  get previousSlide () {
    return this.state.content[this.state.contentIndex - 1]
  }

  get currentSlide () {
    return this.state.content[this.state.contentIndex]
  }

  get nextSlide () {
    return this.state.content[this.state.contentIndex + 1]
  }

  get slideProgressText () {
    const slideCount = this.state.content.length
    const currentSlideNumber = ['finished', 'requires_retry'].includes(this.state.status) ? this.state.content.length : this.state.contentIndex + 1
    return this.state.content.length > 0 ? `${currentSlideNumber}/${slideCount}` : ''
  }

  setSlideCompleted (id) {
    if (this.state.completedSlideIds.includes(id)) return

    const ids = [...this.state.completedSlideIds, id]

    this.setState({
      completedSlideIds: ids
    })

    localStorage.setItem(`${ULEARN_COMPLETED_SLIDES_STORAGE_PREFIX}${this.state.id}`, JSON.stringify(ids))
  }

  // Spacebar to advance handler
  async onKeyDown (e) {
    const { status } = this.state

    if (e.which === 32 && this.advanceSlideOnSpacePress()) {
      if (this.showIntro) {
        this.start()
      } else if (status === 'requires_retry') {
        await this.retry()
      } else {
        this.goToNextSlide()
      }
    }
  }

  canProgress () {
    if (this.state.transitioning) {
      return false
    }

    if (this.slideRef.current && typeof this.slideRef.current.canProgress === 'function') {
      return this.slideRef.current.canProgress()
    }

    return true
  }

  // Allows slide to response to spacebar press without going to the next slide
  // Slide will return false if it doesn't allow progress
  // Seperate from canProgress in case calls made to it aren't directly linked to a user action
  advanceSlideOnSpacePress () {
    return !this.state.transitioning && (this.slideRef.current && typeof this.slideRef.current.advanceSlideOnSpacePress === 'function' ? this.slideRef.current.advanceSlideOnSpacePress() : true)
  }

  updateState (stateUpdate) {
    return new Promise(resolve => {
      this.setState(stateUpdate, async () => {
        resolve()
      })
    })
  }

  updateStatus (status) {
    if (this.state.status.indexOf('ga_intro') === 0) {
      this.setState({ status, gaStarted: true })
    } else {
      this.setState({ status })
    }
  }

  updateSettings (settings) {
    this.setState({ settings })
  }

  goToSlideById (slideId, cb) {
    const { content = [] } = this.state
    const contentIndex = content.findIndex(({ id }) => id === slideId)
    if (contentIndex !== -1) {
      this.goToSlide(contentIndex, cb)
    }
  }

  goToSlide (contentIndex, cb) {
    this.setState({
      contentIndex,
      lastContentIndex: this.state.contentIndex
    }, cb)
  }

  async goToNextSlide () {
    if (!this.canProgress()) {
      return
    }

    await new Promise(resolve => {
      this.setState({ transitioning: true }, async () => {
        const { status, isGapAnalysis } = this.state
        let goToNextSlide = false
        if (status === 'completed' && (isGapAnalysis || !this.nextSlide)) {
          // Finish as we're on the last slide
          // or at the end of Gap Analysis course but not necessarily the questionnaire
          await this.finish()
          // If we aren't at the end of the questionnaire go to the next slide
          goToNextSlide = isGapAnalysis && this.nextSlide
        } else {
          goToNextSlide = ['started', 'completed'].includes(status)
        }
        if (goToNextSlide) {
          this.goToSlide(this.state.contentIndex + 1, resolve)
        } else {
          resolve()
        }
      })
    })
    this.setState({ transitioning: false })
  }

  async goToPrevSlide () {
    if (this.state.transitioning || this.state.contentIndex <= 0) {
      return
    }

    await new Promise(resolve => {
      this.setState({ transitioning: true }, async () => {
        this.goToSlide(this.state.contentIndex - 1, resolve)
      })
    })

    this.setState({ transitioning: false })
  }

  async start () {
    const { status, isGapAnalysis, transitioning } = this.state

    if (transitioning) {
      return
    }

    await new Promise(resolve => {
      this.setState({ transitioning: true }, async () => {
        if (isGapAnalysis) {
          this.updateStatus(status === 'ga_intro_completed' ? 'completed' : 'started')
        } else {
          await this.update({
            action: 'start'
          })
        }
        resolve()
      })
    })
    this.setState({ transitioning: false })
  }

  async update ({ action, ...rest }) {
    try {
      // CL - This "await, initialise var, return" pattern is used as swapping it out for "return this.props.update(...)" meant the catch was never triggered.
      // Without that the error screen isn't shown
      const result = await this.props.update({ action, ...rest })
      return result
    } catch (e) {
      this.handleError(e, { errorType: action, msg: `LearnerCourse.update - ${action} ERROR` })
    }
  }

  async retry () {
    return this.update({
      action: 'retry'
    })
  }

  async finish () {
    return this.update({
      action: 'finish'
    })
  }

  get showIntro () {
    return ['new', 'ga_intro_started', 'ga_intro_completed'].includes(this.state.status)
  }

  renderIntro () {
    const { loading, status } = this.state
    if (loading || !this.showIntro) {
      return null
    }

    const { courseTitle, courseDescription, isGapAnalysis } = this.state

    return (
      <LearnerCourseIntro {...{ courseTitle, courseDescription, isGapAnalysis, status, start: this.start, ...this.mutationProps }} />
    )
  }

  renderContent () {
    const { loading, status, answers, courseId, isGapAnalysis } = this.state
    if (loading || !['started', 'completed'].includes(status) || !this.currentSlide) {
      return null
    }

    const { currentSlide, previousSlide, lastSlide } = this

    // Reinstantiate slide reference as it lost during non linear navigation or transation between content types
    // If this reference is missing slide navigation may not work
    if ((previousSlide && previousSlide.type !== currentSlide.type) ||
      (lastSlide && lastSlide.type !== currentSlide.type) ||
      this.state.lastContentIndex > this.state.contentIndex) {
      this.slideRef = React.createRef()
    }

    const contentProps = {
      goToNextSlide: this.goToNextSlide,
      goToPrevSlide: this.goToPrevSlide,
      slide: this.currentSlide,
      status,
      ref: this.slideRef,
      canGoPrevSlide: previousSlide !== undefined && !isGapAnalysis,
      setSlideCompleted: this.setSlideCompleted
    }
    const LearnerCourseContent = CONTENT_TYPE_COMPONENTS[this.currentSlide.type] || CONTENT_TYPE_COMPONENTS.material
    if (this.currentSlide.type === 'checklist') {
      contentProps.goToSlide = this.goToSlideById
    } else if (this.currentSlide.type !== 'multipleChoice') {
      const { settings: { courseMaterialWaitSeconds = 5 } = {} } = this.state
      if (!this.state.completedSlideIds.includes(this.currentSlide.id)) {
        contentProps.waitMS = courseMaterialWaitSeconds * 1000
      }
    } else if (this.currentSlide.type === 'multipleChoice' && answers) {
      contentProps.courseId = courseId
      contentProps.isGapAnalysis = isGapAnalysis
      contentProps.answer = answers.find(a => a.questionId === this.currentSlide.id && (isGapAnalysis ? a.courseId === this.currentSlide.courseId : true))
    }
    if (this.currentSlide.type === 'vimeo') {
      contentProps.courseResultId = this.state.id
      contentProps.forceSubtitles = this.state.settings?.forceCourseVideoSubtitles === true
      contentProps.forceVimeoPlayerSetting = this.state.settings?.forceCourseVimeoPlayer === true
    }

    return (
      <TransitionWithAnimation animation={FadeAnimation} duration={{ enter: 600, exit: 600 }} innerKey={this.currentSlide.type}>
        <LearnerCourseContent {...contentProps} {...this.mutationProps} />
      </TransitionWithAnimation>
    )
  }

  renderRetry () {
    const { loading, status } = this.state
    if (loading || status !== 'requires_retry') {
      return null
    }

    const { courseTitle, courseIcon, score, settings: { minimumPassScore } = {} } = this.state

    return (
      <LearnerCourseRetry {...{ courseTitle, courseIcon, minimumPassScore, score, ...this.mutationProps }} />
    )
  }

  renderFinished () {
    const { loading, status, companyId } = this.state
    if (loading || status !== 'finished') {
      return null
    }

    const { learnerId, courseTitle, courseIcon, score, isGapAnalysis, learnerName, finishDate, locale, preferredLocale, browserLocale, endUserSessionType } = this.state
    const { preview = false, theme } = this.props

    return (
      <LearnerCourseFinished
        {...{ theme, learnerId, companyId, courseTitle, courseIcon, score, isGapAnalysis, locale, preview, learnerName, preferredLocale, browserLocale, finishDate, endUserSessionType }}
        endUserPortalEnabled={this.state.settings.endUserPortalEnabled}
      />
    )
  }

  renderError () {
    const { loading, status, errorType, errors, loadTime } = this.state
    if (loading || status !== 'error') {
      return null
    }

    return (
      <LearnerCourseError {...{ errorType, errors, loadTime }} />
    )
  }

  renderHolding () {
    const { loading, status, companyId } = this.state

    if (loading || status !== 'plan_invalid') {
      return null
    }

    return (
      <LearnerCourseHolding {...{ companyId }} />
    )
  }

  renderDisabled () {
    const { loading, status } = this.state
    if (loading || status !== 'feature_disabled') {
      return null
    }

    return (
      <LearnerCourseDisabled />
    )
  }

  render () {
    const { status, loading, courseTitle, courseIcon, courseBackgroundImage, isGapAnalysis, settings: { showLanguages = true } = {}, availableLanguages, learnerId, locale, courseId } = this.state
    const showRedirectToPortal = this.state.endUserSessionType === 'limited' || (END_USER_PORTAL && (this.state.settings.endUserPortalEnabled === true && !this.props.preview))
    const { preview } = this.props

    if (loading) return null
    return (
      <>
        <TitleAndIconDispatcher {...{ learnerId, preview }} />
        <Wrap background={!this.showIntro && status !== 'error' && courseBackgroundImage ? `url(${JSON.stringify(courseBackgroundImage)})` : null}>
          {
            loading ? null
              : (
                <>
                  <TitleWrap {...{ preview }}>
                    <TitleLogo loading={loading} />
                    <CourseTitle showLanguages={showLanguages}>
                      <CourseIcon icon={courseIcon} /> {isGapAnalysis ? I18n.t('gapAnalysisCourse', { ...trOpt, courseTitle }) : courseTitle}
                      <LanguageDropdown visible={showLanguages && status !== 'error'} value={locale} languageCodes={availableLanguages} onChange={this.handleLocaleChange} />
                      <CurrentPage>{this.slideProgressText}</CurrentPage>
                      {showRedirectToPortal && (
                        <RedirectToPortalButton
                          companyId={this.state.companyId}
                          endUserSessionType={this.state.endUserSessionType}
                        />
                      )}
                    </CourseTitle>
                    {preview && <CoursePreviewBannerAndKeepAlive {...{ isGapAnalysis, courseId }} />}
                  </TitleWrap>
                  <MainWrap {...{ preview }} useOverflow={status === 'finished' || status === 'requires_retry'}>
                    {this.renderContent()}
                    {this.renderIntro()}
                    {this.renderRetry()}
                    {this.renderFinished()}
                    {this.renderError()}
                    {this.renderHolding()}
                    {this.renderDisabled()}
                  </MainWrap>
                </>
              )
          }
        </Wrap>
      </>
    )
  }
}

LearnerCourse.propTypes = {
  loading: PropTypes.bool,
  mutate: PropTypes.func,
  update: PropTypes.func,
  updateCourseId: PropTypes.func,
  setLoadingVisible: PropTypes.func,
  setIsGapAnalysis: PropTypes.func,
  data: PropTypes.shape({
    status: PropTypes.string,
    learnerId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    learnerName: PropTypes.string,
    courseId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    courseTitle: PropTypes.string,
    courseDescription: PropTypes.string,
    courseIcon: PropTypes.string,
    finishDate: PropTypes.string,
    isGapAnalysis: PropTypes.bool,
    content: PropTypes.arrayOf(PropTypes.object),
    answers: PropTypes.arrayOf(PropTypes.object),
    retries: PropTypes.arrayOf(PropTypes.number),
    score: PropTypes.number,
    settings: PropTypes.shape({
      minimumPassScoreEnabled: PropTypes.bool,
      minimumPassScore: PropTypes.number,
      courseMaterialWaitSeconds: PropTypes.number,
      showLanguages: PropTypes.bool,
      forceCourseVideoSubtitles: PropTypes.bool,
      forceCourseVimeoPlayer: PropTypes.bool
    })
  })
}

LearnerCourse.defaultProps = {
  loading: true,
  mutate: () => {},
  update: () => {},
  updateCourseId: () => {},
  setLoadingVisible: () => {},
  setIsGapAnalysis: () => {},
  data: {
    status: 'started',
    learnerId: null,
    learnerName: null,
    courseId: null,
    courseTitle: null,
    courseIcon: null,
    courseDescription: null,
    isGapAnalysis: false,
    content: [],
    answers: [],
    retries: [],
    score: null,
    settings: {
      minimumPassScoreEnabled: false,
      minimumPassScore: 80,
      courseMaterialWaitSeconds: 5,
      showLanguages: true,
      forceCourseVideoSubtitles: false,
      forceCourseVimeoPlayer: false
    }
  }
}

export default compose(
  withConsumer,
  withTheme
)(LearnerCourse)
