import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import I18n from 'i18n-js'

import { GET_ME } from '../components/Queries/Users'
import { isRiskReportAtStatus } from './company'
import { DEFAULT_LANGUAGE } from '../constants/languages'
import { identifyUserWithPosthog } from './posthog'

export const getMe = async ({ client, noCache = true, throwError = false } = {}) => {
  try {
    const { data } = await client.query({
      query: GET_ME,
      fetchPolicy: noCache ? 'no-cache' : 'cache-first'
    })
    if (throwError && _isNil(_get(data, 'me'))) {
      throw new Error(I18n.t('sessionManager.notLoggedInError'))
    }

    return data
  } catch (e) {
    if (throwError) {
      throw e
    }
    // Do nothing as getMe fails when user is logged out
  }

  return {}
}

export const getInitialSessionStateFromMe = async (client, throwErrorOnFailedGetMe = false) => {
  const data = await getMe({ client, throwError: throwErrorOnFailedGetMe })
  identifyUserWithPosthog(data.me)
  return getInitialSessionStateData(data)
}

export const getSessionFromMeData = data => {
  const {
    me: {
      user: {
        id: userId,
        name,
        firstName,
        lastName,
        companyId,
        commsType,
        locale: userLocale,
        company: {
          name: companyName,
          domain: companyDomain,
          accountType,
          partner = false,
          plan: { planName = null, expires = null, valid: planValid = false, currentLearners = 0 } = {},
          paymentProvided,
          billingType,
          parentCompanyId,
          activeLearnerCount = 0,
          billingSettings,
          managerTenant,
          locale: companyLocale,
          contentLocales,
          timezone: companyTimezone,
          isUsecure = false,
          userAuthOptions
        } = {},
        email,
        intercomHash
      } = {},
      inDisguise,
      originalPermissions,
      multipleDisguises,
      permissions = []
    } = {}
  } = data
  const { startDate: billingStartDate } = billingSettings || {}

  let distributor = null
  let msp = null
  let locale

  if (parentCompanyId && accountType === 'msp') {
    distributor = _get(data, 'me.user.company.parentCompany')
  } else if (parentCompanyId && (accountType === 'tenant' || accountType === 'prospect')) {
    msp = _get(data, 'me.user.company.parentCompany')
    distributor = _get(data, 'me.user.company.parentCompany.parentCompany')
  }

  if (userLocale) {
    locale = userLocale
  } else if (companyLocale) {
    locale = companyLocale
  } else {
    locale = DEFAULT_LANGUAGE
  }

  return {
    userId,
    name,
    firstName,
    lastName,
    companyId,
    companyName,
    companyDomain,
    accountType,
    partner,
    email,
    planName,
    planValid,
    paymentProvided,
    billingType,
    expires,
    inDisguise,
    intercomHash,
    originalPermissions,
    multipleDisguises,
    parentCompanyId,
    currentLearners,
    activeLearnerCount,
    billingStartDate,
    managerTenant,
    distributorId: distributor ? distributor.id : null,
    distributorName: distributor ? distributor.name : null,
    distributorUBreachProEnabled: distributor?.uBreachProEnabled === true,
    mspName: msp ? msp.name : null,
    mspId: msp ? msp.id : null,
    mspUBreachProEnabled: msp?.uBreachProEnabled === true,
    commsType,
    prospectStatus: _get(data, 'me.user.company.riskReport.status'),
    prospectSimulation: _get(data, 'me.user.company.riskReport.simulationId'),
    locale,
    companyLocale,
    companyTimezone,
    contentLocales,
    isUsecure,
    passwordAuthAllowed: userAuthOptions?.password === true,
    type: 'admin',
    permissions
  }
}

export const getSettingsFromMeData = data => getSettingsFromCompany(_get(data, 'me.user.company'))

export const getViewFromMeData = data => {
  const { disableInactiveUsersInReport = false } = getSettingsFromCompany(_get(data, 'me.user.company'))

  return {
    excludeInactiveUsersInReports: disableInactiveUsersInReport
  }
}

export const getSettingsFromCompany = company => {
  const {
    settings: companySettings = {},
    parentDefaultSettings,
    editExternalId,
    accountType,
    disableTenantCreation,
    disableTenantDeletion,
    disableProspectCreation,
    disableProspectDeletion,
    enableProspects,
    enableRiskReportTenants,
    riskReportUPhishCompletionType,
    phishAlertEnabled,
    helpCentreEnabled,
    intercomEnabled,
    enableCreateDomainScan,
    distiMSPInvoicesEnabled,
    learnerAutoEnrolExcludeEnabled,
    locale,
    appProductNames,
    uBreachProEnabled
  } = company || {}

  const settings = {
    locale,
    uLearn: true,
    uPhish: true,
    uBreachEnabled: true,
    endUserPortalEnabled: true,
    uPolicy: true,
    editExternalId,
    disableTenantCreation,
    disableTenantDeletion,
    disableProspectCreation,
    disableProspectDeletion,
    enableProspects, // Feature flag for Prospects in uService
    enableRiskReportTenants, // Feature flag for enabling the creation of risk report for customers in uService
    phishAlertEnabled, // Feature flag to enable the phish alert functionality
    helpCentreEnabled, // Feature flag to enable/disable helpCentre tab in nav bar (default enabled)
    intercomEnabled, // Feature flag to enable/ disable intercom
    parentDefaultSettings,
    riskReportUPhishCompletionType,
    distiMSPInvoicesEnabled,
    ...companySettings,
    enableCreateDomainScan, // Feature flag to enable/disable uBreach Marketing report
    enableLearnerAutoEnrolExclude: learnerAutoEnrolExcludeEnabled === true, // Feature flag to show Exclude from Auto Enrol toggle on create/edit learner modal
    appProductNames,
    uBreachProEnabled
  }

  const prospectStatus = _get(company, 'riskReport.status')
  if (accountType === 'prospect') {
    settings.uLearn = false
    settings.uPhish = isRiskReportAtStatus(prospectStatus, 'uphish_requested')
    settings.uBreachEnabled = isRiskReportAtStatus(prospectStatus, 'ubreach_started')
    settings.uPolicy = false
    settings.endUserPortalEnabled = false
  }

  return settings
}

export const getInitialSessionStateData = data => ({
  session: getSessionFromMeData(data),
  settings: getSettingsFromMeData(data),
  view: getViewFromMeData(data)
})
