/* global localStorage */
import React, { Component, useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Button, Modal, message } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'recompose'
import I18n from 'i18n-js'
import _get from 'lodash/get'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _isEqual from 'lodash/isEqual'
import _isFunction from 'lodash/isFunction'
import _isNil from 'lodash/isNil'
import _isString from 'lodash/isString'
import _keyBy from 'lodash/keyBy'
import _pick from 'lodash/pick'

import {
  GET_SIMULATION_EMAIL_TEMPLATES, GET_SIMULATION_LANDING_PAGE_TEMPLATE, CREATE_SIMULATION_LANDING_PAGE_TEMPLATE, UPDATE_SIMULATION_LANDING_PAGE_TEMPLATE,
  GET_SIMULATION_LANDING_PAGE_LAYOUTS, CHECK_SIMULATION_LANDING_PAGE_ACCESS, CHECK_SIMULATION_LANDING_PAGE_LOCALES
} from '../Queries/uPhish'
import { GET_COMPANIES } from '../Queries/Companies'
import MutationForm from '../MutationForm/MutationForm'
import { MutationFormFooter } from '../../components/MutationForm'
import { LoadingBlock, ErrorAlerts } from '../common'
import { connect, withConsumer } from '../../hocs'
import selectors from '../../state/selectors'
import { showErrors, invalidateLandingPageQueryCache, renderToString, modalConfirmAsync } from '../../helpers'
import { clearLandingPageContentCache } from '../../helpers/uPhish'
import { ContentTriggerAlert } from './ValidationAlert'
import {
  managedByUsecure, buildCompanyOptions,
  getGlobalLabel, getGlobalExtra, getCompanyAccessLabel, getCompanyAccessPlaceholder, getCompanyAccessExtra
} from '../../helpers/company'
import { renderListFragmentFromHtmlArray } from '../../helpers/renderFromArray'
import { LANGUAGE_NAMES_BY_CODE, LANGUAGE_SELECT_OPTIONS } from '../../constants/languages'
import { CATEGORY_OPTIONS, UNLAYER_LANDING_PAGE_CONTENT_FIELD_CONFIG } from '../../constants/uPhish'
import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'

const trOpt = { scope: 'uPhish.landingPageForm' }
const commonTrOpt = { scope: 'uPhish.common' }

const defaultState = {
  name: null,
  pageTitle: null,
  category: null,
  tile: null,
  content: null,
  companyId: null,
  global: false,
  companies: []
}

const HELP_TRIGGER_CLASS = renderToString(<strong>usecure-event-trigger</strong>)
const HELP_PASSWORD_FIELD_NAME = renderToString(<strong>usecure_password</strong>)

const helpTrOpt = { scope: `${trOpt.scope}.contentEditorHelp` }
const ContentEditorHelpSection = ({ scope, list, footer = false }) => {
  if (!scope) {
    return null
  }

  const sectionTrOpt = { scope: `${helpTrOpt.scope}.${scope}` }
  if (scope === 'compromiseTriggerForm') {
    sectionTrOpt.trigger = HELP_TRIGGER_CLASS
  } else if (scope === 'passwordField') {
    sectionTrOpt.password = HELP_PASSWORD_FIELD_NAME
  }

  return (
    <>
      <h4>{I18n.t('title', sectionTrOpt)}</h4>
      <ul>
        {list}
        {renderListFragmentFromHtmlArray(I18n.t('list', sectionTrOpt))}
      </ul>
      {footer}
    </>
  )
}

const linkTrOpt = { scope: `${helpTrOpt.scope}.compromiseTriggerLink` }

const _ContentEditorHelp = ({ className }) => {
  const selectFormBlock = <li>{I18n.t('selectFormBlock', helpTrOpt)}</li>
  return (
    <div {...{ className }}>
      <ContentEditorHelpSection
        scope='compromiseTriggerForm'
        list={selectFormBlock}
      />
      <ContentEditorHelpSection
        scope='passwordField'
        list={selectFormBlock}
      />
      <ContentEditorHelpSection scope='compromiseTriggerButton' />
      <ContentEditorHelpSection
        scope='compromiseTriggerLink'
        footer={
          <p className='base-font'>
            {I18n.t('tokenInfo', linkTrOpt)}
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: I18n.t('tokenExample', {
                  ...linkTrOpt,
                  link: renderToString(<a href>{I18n.t('downloadDocument', linkTrOpt)}</a>),
                  token: I18n.t('downloadDocument', linkTrOpt)
                })
              }}
            />
          </p>
        }
      />
    </div>
  )
}
const ContentEditorHelp = styled(_ContentEditorHelp)`
  max-width: 525px;

  ul {
    padding-inline-start: 18px;
  }
`

class LandingPageForm extends Component {
  constructor (props) {
    super(props)

    this.form = React.createRef()

    this.load = this.load.bind(this)
    this.handleCancelClick = this.handleCancelClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
    this.handleFailure = this.handleFailure.bind(this)
    this.renderFooter = this.renderFooter.bind(this)
    this.getGlobalLabel = this.getGlobalLabel.bind(this)
    this.getGlobalExtra = this.getGlobalExtra.bind(this)
    this.isGlobalAllowed = this.isGlobalAllowed.bind(this)
    this.getCompanyAccessLabel = this.getCompanyAccessLabel.bind(this)
    this.getCompanyAccessPlaceholder = this.getCompanyAccessPlaceholder.bind(this)
    this.getCompanyAccessExtra = this.getCompanyAccessExtra.bind(this)
    this.isCompanyAccessAllowed = this.isCompanyAccessAllowed.bind(this)
    this.reset = this.reset.bind(this)
    this.validateContent = this.validateContent.bind(this)
    this.mutateValues = this.mutateValues.bind(this)
    this.hasAllSessionPermissions = this.hasAllSessionPermissions.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)

    this.state = {
      ...defaultState,
      companyOptions: [],
      companyMap: {},
      emailTemplateOptions: [],
      contentValid: false,
      failed: false
    }
  }

  get id () {
    return this.props.id
  }

  get isCreate () {
    return _isNil(this.id)
  }

  hasAllSessionPermissions (requiredPermissions = []) {
    return _isFunction(this.props.hasAllSessionPermissions) ? this.props.hasAllSessionPermissions(requiredPermissions) : false
  }

  get isClone () {
    return this.props.clone === true && !_isNil(this.id)
  }

  get isUpdate () {
    return this.props.clone !== true && !_isNil(this.id)
  }

  get useStorage () {
    return this.isCreate || this.isClone
  }

  get storageId () {
    const { userId, companyId, id, embedded } = this.props
    let prefix = 'create'
    if (this.isUpdate) {
      prefix = 'update'
    } else if (this.isClone) {
      prefix = 'clone'
    }

    // Local storage isn't used when updating but there's a different storage id just in case this changes
    return `${prefix}SimLandingPage|${embedded ? '|embedded' : ''}${this.isCreate ? '' : `|${id}`}|${companyId}|${userId}`
  }

  handleTabChange (id) {
    if (id === 'access') {
      this.props.setAccessControlOpened(true)
    }
  }

  async handleChange (name, value) {
    if (this.useStorage) {
      await this.updateState({ [name]: value })
    }

    if (name === 'content') {
      this.updateContentValid(value)
    } else if (this.isUsecureAdmin && name === 'companyId') {
      this.props.onCompanyIdChange(value)
    }
  }

  updateContentValid (rawContent) {
    const content = _get(rawContent, 'html', '')

    let contentValid = false
    if (content) {
      // Lazy check for usecure-event-trigger class
      contentValid = [/\[\[(.*)\]\]/, /usecure-event-trigger/, /%usecure_event_trigger%/].some(patt => patt.test(content))
    }
    this.setState({ contentValid })
  }

  validateContent (field, value, errors) {
    if (!this.state.contentValid) {
      errors.push(I18n.t('compromiseTriggerError', trOpt))
    }
  }

  async handleSuccess (result) {
    message.success(I18n.t('savedMessage', trOpt))
    this.props.onSuccess(result)
    this.resetStorage()
    if (this.isUpdate) {
      await clearLandingPageContentCache(this.id, { skipMessage: true })
    }
  }

  async checkAccess (values) {
    if (!this.allowAccessControl) return

    const { emailTemplates, companies, companyId, global } = values
    const result = await this.props.client.query({
      query: CHECK_SIMULATION_LANDING_PAGE_ACCESS,
      variables: {
        emailTemplates,
        companyId: companyId === 'usecure' ? null : companyId,
        companies,
        global
      },
      fetchPolicy: 'no-cache'
    })

    if (_get(result, 'data.checkLandingPageAccess.conflict') === true) {
      const conflictEmailTemplates = _get(result, 'data.checkLandingPageAccess.emailTemplates', [])
      const accessType = this.getAccessType(values)

      const continueSubmit = await modalConfirmAsync({
        title: I18n.t('uPhish.emailTemplateForm.accessControlTitle'),
        content: (
          <div>
            <p className='base-font'>{I18n.t(accessType === 'distributor' ? 'accessControlIntroDistributor' : 'accessControlIntro', trOpt)}</p>
            <p className='base-font'>{I18n.t('affectedEmailTemplatesList', commonTrOpt)}</p>
            <ul>
              {conflictEmailTemplates.map(e => <li key={e.id}>{e.name}</li>)}
            </ul>
            <p className='base-font'>{I18n.t('accessControlInfo', trOpt)}</p>
          </div>
        ),
        width: 600
      })
      if (!continueSubmit) {
        throw new Error('cancelSubmit')
      }
    }
  }

  async checkLocales (values) {
    const { emailTemplates, companyId, locales } = values
    const localesResult = await this.props.client.query({
      query: CHECK_SIMULATION_LANDING_PAGE_LOCALES,
      variables: {
        companyId: companyId === 'usecure' ? null : companyId,
        emailTemplates,
        locales
      },
      fetchPolicy: 'no-cache'
    })

    if (_get(localesResult, 'data.checkLandingPageLocales.mismatch') === true) {
      const mismatchedEmailTemplates = _get(localesResult, 'data.checkLandingPageLocales.emailTemplates') || []
      const continueSubmit = await modalConfirmAsync({
        title: I18n.t('localesMismatchTitle', commonTrOpt),
        content: (
          <div>
            <p className='base-font'>{I18n.t('localesMismatchIntro', trOpt)}</p>
            <p className='base-font'>{I18n.t('affectedEmailTemplatesList', commonTrOpt)}</p>
            <ul>
              {mismatchedEmailTemplates.map(({ id, name, locales }) => (
                <li key={id}>
                  <div>{name}</div>
                  <ul>
                    {
                      locales?.length > 0
                        ? locales.map(locale => <li key={locale}>{LANGUAGE_NAMES_BY_CODE[locale]}</li>)
                        : <li>{I18n.t('common.noLanguagesSet')}</li>
                    }
                  </ul>
                </li>
              ))}
            </ul>
            <p className='base-font'>{I18n.t('localesMismatchInfo', trOpt)}</p>
          </div>
        ),
        width: 600
      })
      if (!continueSubmit) {
        throw new Error('cancelSubmit')
      }
    }
  }

  async handleSubmit (values) {
    if (_isEmpty(values?.emailTemplates)) return

    await this.checkAccess(values)
    await this.checkLocales(values)
  }

  handleFailure (e) {
    if (_get(e, 'message') === 'cancelSubmit') {
      return
    }

    const { onFailure, failureMessage } = this.props
    if (_isFunction(onFailure)) {
      onFailure(e)
    } else {
      showErrors(e, _isString(onFailure) ? onFailure : failureMessage)
    }
  }

  handleCancelClick () {
    Modal.confirm({
      title: I18n.t('common.cancelConfirmDialog'),
      okText: I18n.t('common.yes'),
      cancelText: I18n.t('common.no'),
      onOk: () => {
        this.props.onCancel()
        this.resetStorage()
      }
    })
  }

  getStoredState () {
    let storedState
    try {
      const storedStateString = localStorage.getItem(this.storageId)
      if (storedStateString) {
        storedState = JSON.parse(storedStateString)
      }
      if (this.isUsecureAdmin && storedState?.companyId === null) {
        storedState.companyId = 'usecure'
      }
    } catch (e) { }
    return storedState
  }

  async loadPrompt () {
    if (this.props.embedded) {
      return
    }

    const storedState = this.getStoredState()
    if (storedState) {
      // Set state using previous creation attempt
      await this.load(storedState)
      Modal.confirm({
        title: I18n.t('common.resumeConfirmDialog.title'),
        okText: I18n.t('common.resumeConfirmDialog.ok'),
        cancelText: I18n.t('common.resumeConfirmDialog.cancel'),
        onCancel: this.reset
      })
    } else if (this.isClone) {
      await this.load(this.templateValues)
    }
  }

  async load (storedState = this.getStoredState()) {
    if (storedState) {
      await this.updateState(storedState)
      return this.form.current.setInitialValues(storedState)
    }
  }

  updateState (stateUpdate) {
    return new Promise(resolve => {
      this.setState(stateUpdate, async () => {
        this.updateLocalStorage()
        resolve()
      })
    })
  }

  updateLocalStorage () {
    if (!this.useStorage) return
    const values = _pick(this.state, [
      'name', 'pageTitle', 'category', 'tile', 'emailTemplates',
      'content', 'companyId', 'global', 'companies', 'locales'
    ])
    localStorage.setItem(this.storageId, JSON.stringify(values))
  }

  async reset () {
    await this.updateState(defaultState)
    this.resetStorage()
    if (this.isClone) {
      return this.load(this.templateValues)
    }
    await this.form.current.resetFields()
    return this.form.current.refreshFieldElements()
  }

  resetStorage () {
    localStorage.removeItem(this.storageId)
  }

  get hasSuperPermission () {
    return this.hasAllSessionPermissions(this.isCreate || this.isClone ? [permissions.LANDING_PAGE_SUPER_CREATE] : [permissions.LANDING_PAGE_SUPER_UPDATE])
  }

  get hasSuperAccessControlPermission () {
    return this.hasAllSessionPermissions([permissions.LANDING_PAGE_SUPER_ACCESS_CONTROL])
  }

  get allowAccessControl () {
    return this.hasSuperAccessControlPermission || (['distributor', 'msp'].includes(this.props.accountType) && this.hasAllSessionPermissions([permissions.LANDING_PAGE_ACCESS_CONTROL]))
  }

  setEmailTemplateOptions () {
    const { hasSuperAccessControlPermission } = this
    this.setState({
      emailTemplateOptions: this.props.simulationEmailTemplates.map(template => {
        let companyName = ''
        let languages = ''
        if (hasSuperAccessControlPermission) {
          companyName = ` (${template.companyId === null ? 'Managed by usecure' : _get(template, 'company.name')})`
        }
        const { locales } = template
        const languageNames = _isArray(locales) && !_isEmpty(locales) ? locales.map(l => LANGUAGE_NAMES_BY_CODE[l]).sort((a, b) => a.localeCompare(b)) : []
        languages = ` - ${_isEmpty(languageNames) ? I18n.t('common.none') : languageNames.join(', ')}`
        return { value: template.id, label: `${template.name}${companyName}${languages}` }
      })
    })
  }

  setCompanyData () {
    const { companies: queryCompanies = [], template, disableCompanyFields } = this.props
    let companies = []
    if (template && (disableCompanyFields || queryCompanies.length === 0)) {
      // Use the landing page's company and company access list as a basis for company select field options to reduce the likelihood of UUIDs being visible
      // This applies either until the company query has loaded or until it fails i.e. disableCompanyFields is true
      if (template.company) {
        companies.push(template.company)
      }
      if (template.companies) {
        companies.push(...template.companies)
      }
    } else {
      // Otherwise use companies loaded from query
      companies = queryCompanies
    }

    const companyMap = _keyBy(companies, 'id')
    let companyOptions = []
    if (this.hasSuperAccessControlPermission) {
      companyOptions = buildCompanyOptions(
        companies.map(company => {
          const linkFieldValue = ['usecure', null]
          if (company.parentCompanyId) {
            linkFieldValue.push(company.parentCompanyId)
            const parentCompany = companyMap[company.parentCompanyId]
            if (parentCompany && parentCompany.accountType === 'msp' && parentCompany.parentCompanyId) {
              linkFieldValue.push(parentCompany.parentCompanyId)
            }
          }
          return { ...company, linkFieldValue }
        })
      )
    } else {
      companyOptions = buildCompanyOptions(companies)
    }

    this.setState({
      companyMap,
      companyOptions
    })
  }

  getAccessType (values) {
    const { hasSuperAccessControlPermission, props: { accountType }, state: { companyMap } } = this
    if (hasSuperAccessControlPermission && managedByUsecure(values)) {
      return 'usecure'
    }
    const viewAccountType = hasSuperAccessControlPermission ? _get(companyMap, `${values.companyId}.accountType`) : accountType
    return viewAccountType || null
  }

  isGlobalAllowed (values) {
    return this.allowAccessControl && ['usecure', 'distributor', 'msp'].includes(this.getAccessType(values))
  }

  getGlobalLabel (values) {
    return getGlobalLabel(this.getAccessType(values), I18n.t('landingPage', trOpt), this.hasSuperAccessControlPermission)
  }

  getGlobalExtra (values) {
    return getGlobalExtra(this.getAccessType(values), I18n.t('landingPage', trOpt), this.hasSuperAccessControlPermission)
  }

  isCompanyAccessAllowed (values) {
    return this.allowAccessControl && values.global !== true && ['usecure', 'distributor', 'msp'].includes(this.getAccessType(values))
  }

  getCompanyAccessLabel (values) {
    return getCompanyAccessLabel(this.getAccessType(values))
  }

  getCompanyAccessPlaceholder (values) {
    return getCompanyAccessPlaceholder(this.getAccessType(values))
  }

  getCompanyAccessExtra (values) {
    return getCompanyAccessExtra(this.getAccessType(values), I18n.t('landingPage', trOpt))
  }

  get templateValues () {
    const { template } = this.props
    const { name, locales, pageTitle, category: templateCategory, tile, content, emailTemplateIds: emailTemplates = [], companyId } = template || {}

    // Category is left blank on create to encourage the setting of a meaningful category
    let category = null
    if (this.isUpdate) {
      // Set category to "No Category" on update when the template's category is empty
      category = templateCategory ?? 'none'
    } else if (this.isClone) {
      // Use original category (if populated) when cloning a landing page
      category = templateCategory
    }

    return {
      name,
      locales,
      pageTitle,
      category,
      tile,
      emailTemplates,
      content,
      companyId: companyId === null ? 'usecure' : companyId,
      global: _get(template, 'global', false),
      companies: _get(template, 'companies', []).map(company => _isString(company) ? company : _get(company, 'id', null)).filter(id => !_isNil(id))
    }
  }

  async componentDidMount () {
    this.setCompanyData()
    this.setEmailTemplateOptions()
    if (this.isUpdate) {
      await this.load(this.templateValues)
    }
    if (this.useStorage) {
      await this.loadPrompt()
    }
  }

  async componentDidUpdate (prevProps) {
    const { companies = [], simulationEmailTemplates, template } = this.props
    const { companies: prevCompanies = [], simulationEmailTemplates: prevSimulationEmailTemplates, template: prevTemplate } = prevProps
    if (!_isEqual(companies, prevCompanies)) {
      this.setCompanyData()
    }
    if (!_isEqual(simulationEmailTemplates, prevSimulationEmailTemplates)) {
      this.setEmailTemplateOptions()
    }
    if (this.isUpdate && (!prevTemplate || !_isEqual(template, prevTemplate))) {
      await this.load(this.templateValues)
    }
  }

  renderFooter ({ submitLabel, valid, loading, footerAlign, disabled }) {
    return (
      <MutationFormFooter footerAlign={footerAlign}>
        <Button
          type='primary' ghost disabled={disabled} loading={loading}
          htmlType='submit'
        >{submitLabel}
        </Button>
        {this.props.showCancel && <Button ghost type='danger' disabled={loading} onClick={this.handleCancelClick}>{I18n.t('common.cancel')}</Button>}
      </MutationFormFooter>
    )
  }

  mutateValues ({ category, emailTemplates, ...values }) {
    return {
      ...values,
      category: category === 'none' ? null : category,
      emailTemplates: _isArray(emailTemplates) && !_isEmpty(emailTemplates) ? emailTemplates : null
    }
  }

  render () {
    const mutation = this.isUpdate ? UPDATE_SIMULATION_LANDING_PAGE_TEMPLATE : CREATE_SIMULATION_LANDING_PAGE_TEMPLATE
    const { hasSuperPermission, hasSuperAccessControlPermission } = this
    const { companyOptions, contentValid, emailTemplateOptions } = this.state
    const { companyId, embedded, layouts, contentLocales: defaultContentLocales, disableCompanyFields } = this.props

    const tabs = []
    tabs.push({
      id: 'content',
      title: I18n.t('landingPage', commonTrOpt)
    })
    if (this.allowAccessControl) {
      tabs.push({
        id: 'access',
        title: I18n.t('common.accessControl.tabTitle')
      })
    }

    const fields = [
      {
        id: 'name',
        label: I18n.t('templateName', commonTrOpt),
        type: 'text',
        required: true,
        tab: 'content'
      }, {
        id: 'locales',
        label: I18n.t('common.languagesParenPlural'),
        type: 'multiSelect',
        options: LANGUAGE_SELECT_OPTIONS,
        sortOptions: true,
        defaultValue: (this.isCreate && !hasSuperPermission ? defaultContentLocales : []),
        placeholder: I18n.t('common.fields.languagesPlaceHolder'),
        required: true,
        requiredError: I18n.t('common.fields.languagesRequiredError'),
        tab: 'content'
      }, {
        id: 'pageTitle',
        label: I18n.t('pageTitle', commonTrOpt),
        type: 'text',
        required: true,
        tab: 'content'
      }, {
        id: 'category',
        label: I18n.t('common.category'),
        type: 'select',
        options: CATEGORY_OPTIONS.sort((a, b) => {
          if (a.value === 'none') return -1
          if (b.value === 'none') return 1
          return a.label.localeCompare(b.label)
        }),
        required: true,
        tab: 'content'
      }, {
        id: 'tile',
        label: I18n.t('tileImage', commonTrOpt),
        type: 'image',
        required: false,
        tab: 'content'
      }, {
        id: 'emailTemplates',
        label: I18n.t('emailTemplates', trOpt),
        type: 'multiSelect',
        options: emailTemplateOptions,
        maxTagTextLength: 60,
        required: false,
        tab: 'content'
      }, {
        id: 'content',
        label: I18n.t('common.fields.content'),
        type: 'unlayer',
        displayMode: 'web',
        designMode: 'edit',
        ...UNLAYER_LANDING_PAGE_CONTENT_FIELD_CONFIG,
        required: true,
        validate: this.validateContent,
        layouts,
        helpTitle: I18n.t('helpTitle', trOpt),
        helpContent: <ContentEditorHelp />,
        tab: 'content'
      }, {
        type: 'custom',
        component: ContentTriggerAlert,
        visible: contentValid !== true,
        tab: 'content'
      }, {
        id: 'companyId',
        label: I18n.t('common.accessControl.ownedBy'),
        type: 'select',
        required: true,
        options: [
          { value: 'usecure', label: I18n.t('common.managedByUsecure') },
          ...companyOptions
        ],
        defaultValue: hasSuperAccessControlPermission && !embedded ? 'usecure' : companyId,
        mutateValue: value => value === 'usecure' ? null : value,
        visible: hasSuperAccessControlPermission,
        disabled: disableCompanyFields,
        formItemStyle: { maxWidth: 400 },
        tab: 'access'
      }, {
        label: this.getGlobalLabel,
        id: 'global',
        required: false,
        type: 'checkbox',
        mutateValue: (value, values) => this.isGlobalAllowed(values) ? value : false,
        visible: this.isGlobalAllowed,
        extra: this.getGlobalExtra,
        tab: 'access'
      }, {
        id: 'companies',
        label: this.getCompanyAccessLabel,
        type: 'multiSelect',
        required: false,
        placeholder: this.getCompanyAccessPlaceholder,
        extra: this.getCompanyAccessExtra,
        options: companyOptions,
        mutateValue: (value, values) => this.isCompanyAccessAllowed(values) ? (value || []) : [],
        visible: this.isCompanyAccessAllowed,
        disabled: disableCompanyFields,
        formItemStyle: { maxWidth: 800 },
        linkField: hasSuperAccessControlPermission ? 'companyId' : null,
        linkFieldValue: hasSuperAccessControlPermission ? 'ancestors' : null,
        tab: 'access'
      }
    ]

    return (
      <MutationForm
        ref={this.form}
        {...{ mutation, fields, tabs }}
        mutateValues={this.mutateValues}
        submitLabel={I18n.t('common.save')}
        variables={{ id: this.id }}
        skipResetFieldsOnSubmit
        disableSubmitIfInvalid={false}
        onChange={this.handleChange}
        onSuccess={this.handleSuccess}
        onSubmit={this.handleSubmit}
        onFailure={this.handleFailure}
        footer={this.renderFooter}
        update={invalidateLandingPageQueryCache}
        onTabChange={this.handleTabChange}
      />
    )
  }
}

LandingPageForm.propTypes = {
  id: PropTypes.string,
  onFailure: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onSuccess: PropTypes.func,
  hasAllSessionPermissions: PropTypes.func
}
LandingPageForm.defaultProps = {
  id: null,
  onSuccess: () => { },
  hasAllSessionPermissions: () => false
}

const LandingPageFormQuery = React.forwardRef((props, ref) => {
  const { id, accountType, onLoadError: onLoadErrorProp, companyId, clone: isClone } = props
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const hasSuperAccessControlPermission = hasAllSessionPermissions([permissions.LANDING_PAGE_SUPER_ACCESS_CONTROL])
  const hasAccessControlPermission = hasAllSessionPermissions([permissions.LANDING_PAGE_ACCESS_CONTROL])

  const [accessControlOpened, setAccessControlOpened] = useState(false)
  const allowAccessControl = hasSuperAccessControlPermission || (['distributor', 'msp'].includes(accountType) && hasAccessControlPermission)

  const [ownerCompanyId, setOwnerCompanyId] = useState(null)
  const [emailTemplateQueryOpt, setEmailTemplateQueryOpt] = useState({ skip: true })
  const [failed, updateFailed] = useState(false)
  const onLoadError = useCallback((error, defaultError) => {
    updateFailed(true)
    showErrors(error, defaultError)
    if (_isFunction(onLoadErrorProp)) {
      onLoadErrorProp(error)
    }
  }, [onLoadErrorProp])
  const onLandingPageError = useCallback(error => onLoadError(error, I18n.t('landingPageError', trOpt)), [onLoadError])
  const onEmailTemplatesError = useCallback(error => onLoadError(error, I18n.t('emailTemplatesError', trOpt)), [onLoadError])
  const onCompaniesError = useCallback(error => onLoadError(error, I18n.t('errors., commonTrOptcompaniesError')), [onLoadError])

  const { loading: companiesLoading, error: companiesError, data: companiesData } = useQuery(GET_COMPANIES, {
    skip: !accessControlOpened || !allowAccessControl,
    variables: {
      withAccountType: true,
      withParentCompanyId: true,
      descendants: !hasSuperAccessControlPermission
    },
    onError: onCompaniesError
  })
  const { companies = [] } = companiesData || {}

  const { loading: landingPageLoading, error: landingPageError, data: { simulationLandingPage: template } = {} } = useQuery(GET_SIMULATION_LANDING_PAGE_TEMPLATE, {
    skip: !id,
    variables: { id, forUpdate: !isClone },
    onError: onLandingPageError
  })

  // Initialise ownerCompanyId state hook when usecure admin edits a landing page
  useEffect(() => {
    if (!hasSuperAccessControlPermission) return
    let ownerCompanyId = null
    if (id && template?.companyId) {
      // usecure admin updating a client owned landing page
      ownerCompanyId = template.companyId
    } else if (!id || template?.companyId === null) {
      // usecure admin updating a "Managed by usecure" landing page
      // or usecure admin creating a landing page which defaults to "Managed by usecure"
      ownerCompanyId = 'usecure'
    }
    setOwnerCompanyId(ownerCompanyId)
  }, [hasSuperAccessControlPermission, id, template, companyId])

  // Change email template query variables based on changes to ownerCompanyId
  useEffect(() => {
    const opt = { skip: false, variables: {} }
    if (ownerCompanyId === 'usecure') {
      // usecure admin editing a "Managed by usecure" landing page
      // email template options should be restricted to "Managed by usecure" templates
      opt.variables.defaultAll = true
    } else if (ownerCompanyId) {
      // usecure admin editing a client owned landing page
      // email template options should be restricted to template available to that client's company
      opt.variables.companyId = ownerCompanyId
    } else if (hasSuperAccessControlPermission) {
      // usecure admin editing a landing page where the owner is known yet
      // Don't load email templates until ownerCompanyId is set
      opt.skip = true
    }

    if (_isEmpty(opt.variables)) {
      delete opt.variables
    }
    setEmailTemplateQueryOpt(opt)
  }, [ownerCompanyId, hasSuperAccessControlPermission])

  // Update ownerCompanyId when companyId field is changed on the form
  const onCompanyIdChange = useCallback(value => setOwnerCompanyId(value), [])

  const { loading: emailTemplatesLoading, error: emailTemplatesError, data: { simulationEmailTemplates = [] } = {} } = useQuery(GET_SIMULATION_EMAIL_TEMPLATES, {
    onError: onEmailTemplatesError,
    ...emailTemplateQueryOpt
  })

  const { loading: layoutsLoading, data: { landingPageLayouts: layouts = [] } = {} } = useQuery(GET_SIMULATION_LANDING_PAGE_LAYOUTS)

  if (emailTemplatesError) return <ErrorAlerts error={emailTemplatesError} defaultError={I18n.t('emailTemplatesError', trOpt)} />
  if (failed || landingPageError) return <ErrorAlerts error={landingPageError} defaultError={I18n.t('landingPageError', trOpt)} />

  return (
    <>
      {companiesError && <ErrorAlerts error={companiesError} defaultError={I18n.t('errors.companiesError', commonTrOpt)} />}
      <LoadingBlock fullScreen loading={companiesLoading || emailTemplatesLoading || landingPageLoading || layoutsLoading} />
      {
        (!id || template) && (
          <LandingPageForm
            {...props}
            {...{
              ref,
              companies,
              template,
              simulationEmailTemplates,
              layouts,
              onCompanyIdChange,
              hasAllSessionPermissions,
              accessControlOpened,
              setAccessControlOpened
            }}
            disableCompanyFields={!_isNil(companiesError)}
          />
        )
      }
    </>
  )
})

export default compose(
  withConsumer,
  connect(
    state => _pick(selectors.session.get(state), ['userId', 'companyId', 'accountType', 'contentLocales'])
  )
)(LandingPageFormQuery)
