import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Tabs, Icon } from 'antd'
import I18n from 'i18n-js'
import { css } from 'emotion'
import _isFunction from 'lodash/isFunction'
import _isBoolean from 'lodash/isBoolean'
import _isString from 'lodash/isString'

import {
  MutationFormInput,
  MutationFormTextArea,
  MutationFormSelect,
  MutationFormTreeSelect,
  MutationFormInputNumber,
  MutationFormImageUpload,
  MutationFormFileUpload,
  MutationFormCheckbox,
  MutationFormColourPicker,
  MutationFormInputWithSelect,
  MutationFormEmailBody,
  MutationFormSwitch,
  MutationFormLearnerAndGroupSelect,
  MutationFormDateTimePicker,
  MutationFormRadio,
  MutationFormTimeRange,
  MutationFormSlate,
  MutationFormUnlayer,
  MutationFormTextTags,
  MutationFormFooter,
  MutationFormSubmitButton,
  MutationFormDateRangePicker,
  MutationFormTimezone,
  MutationFormCollapse
} from './'

const trOpt = { scope: 'mutationForm.mutationForm' }

const { TabPane } = Tabs

const EditorEnd = React.forwardRef(({ component }, ref) => {
  return (
    <div
      ref={ref}
      className={css`padding: 20px;`}
    >
      {component}
    </div>
  )
})

// The form is own element to avoid an onSubmit handler like this:
// onSubmit={event => this.onSubmit(event, mutate)}
// This kind of definition declares a new function every render and can impact performance
// However the way Mutation works could negate the benefits of this pattern making the code unnecessarily complex
// CL - I only started using this pattern after an eslint script I used once went mental without these changes
// CL - MutationForm now uses an ad hoc mutate call rather than the Mutation component so this component could merged into MutationForm
class MutationFormElement extends Component {
  constructor (props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)

    const { fields = [] } = props
    this.fieldRefs = fields.reduce((refs, field) => {
      refs[field.id] = React.createRef()

      if (field.type === 'collapse') {
        for (const subField of field.fields) {
          refs[subField.id] = React.createRef()
        }
      }
      return refs
    }, {})
    this.editorEndRef = React.createRef()
  }

  callFieldFunction (fieldId, funcName, ...args) {
    const fieldFunc = this.fieldRefs[fieldId]?.current?.[funcName]
    if (_isFunction(fieldFunc)) {
      fieldFunc(...args)
    }
  }

  callFunctionOnAllFields (funcName, ...args) {
    Object.keys(this.fieldRefs).forEach(id => {
      this.callFieldFunction(id, funcName, ...args)
    })
  }

  refresh () {
    this.callFunctionOnAllFields('refresh')
  }

  reset () {
    this.callFunctionOnAllFields('reset')
  }

  getFieldComponentErrors (field, value, errors, opt) {
    this.callFieldFunction(field.id, 'validate', value, errors, opt, this.props.values)
  }

  // CL - Only MutationFormInput has a focus method at present
  focusOnField (fieldId) {
    this.callFieldFunction(fieldId, 'focus')
  }

  handleSubmit (event) {
    this.props.onSubmit(event)
  }

  renderFooter () {
    const { submitLabel, valid, loading, footer, footerAlign, disableSubmitIfInvalid, submitIcon, footerProps, btnBlock, disableSubmit, readOnly } = this.props

    const disabled = loading || (disableSubmitIfInvalid ? !valid : false) || disableSubmit || readOnly
    return footer ? footer({ submitLabel, valid, loading, footerAlign, disabled, submitIcon, footerProps, btnBlock }) : (
      <MutationFormFooter footerAlign={footerAlign}>
        <MutationFormSubmitButton {...{ submitLabel, submitIcon, disabled, loading, block: btnBlock }} />
      </MutationFormFooter>
    )
  }

  isFieldVisible (field) {
    return this.getFieldBooleanPropValue(field, 'visible')
  }

  isFieldDisabled (field) {
    return this.props.loading || this.props.disabled || this.getFieldBooleanPropValue(field, 'disabled', false)
  }

  getFieldBooleanPropValue (field, propId, defaultValue = true) {
    const { [propId]: fieldProp } = field
    return _isFunction(fieldProp) ? fieldProp(this.props.values) : (_isBoolean(fieldProp) ? fieldProp : defaultValue)
  }

  renderField (field) {
    const { values, errors, onChange, disableSubmitOnEnter, changed, readOnly } = this.props
    const {
      component: selfFieldComponent, renderAsInput,
      visible: fieldVisible, required: fieldRequired, disabled: fieldDisabled,
      label: fieldLabel, extra: fieldExtra, placeholder: fieldPlaceholder,
      linkField: fieldLinkField, optionLabelProp: fieldOptionLabelProp,
      useFormValues,
      ...selfFieldProps
    } = field
    const visible = this.isFieldVisible(field)
    const required = _isFunction(fieldRequired) ? fieldRequired(values) : (_isBoolean(fieldRequired) ? fieldRequired : false)
    const label = _isFunction(fieldLabel) ? fieldLabel(values) : (fieldLabel || null)
    const extra = _isFunction(fieldExtra) ? fieldExtra(values) : (fieldExtra || null)
    const placeholder = _isFunction(fieldPlaceholder) ? fieldPlaceholder(values) : (_isString(fieldPlaceholder) ? fieldPlaceholder : null)
    const linkField = _isFunction(fieldLinkField) ? fieldLinkField(values) : (_isString(fieldLinkField) ? fieldLinkField : null)
    const optionLabelProp = _isFunction(fieldOptionLabelProp) ? fieldOptionLabelProp(values) : (_isString(fieldOptionLabelProp) ? fieldOptionLabelProp : undefined)
    const disabled = readOnly || this.isFieldDisabled(field)
    // validWhenHidden=false allows for validation of invisible fields as fields need to rendered for the FieldComponent.validate calls to works (uses refs)
    // This is definitely an edge case if it never comes up then validWhenHidden can be deprecated
    if (!visible && field.validWhenHidden) {
      return null
    }

    const formValues = _isFunction(useFormValues) ? useFormValues(values) : useFormValues ? values : null

    // Pass visible as prop so that field refs remain in place, means every field needs to handle visibility in its own way
    const fieldProps = {
      disableSubmitOnEnter,
      ...selfFieldProps,
      onChange,
      value: values[field.id],
      errors: errors[field.id],
      changed: changed[field.id] === true,
      visible,
      required,
      disabled,
      label,
      extra,
      placeholder,
      formValues,
      linkField,
      optionLabelProp
    }
    let FieldComponent
    if (typeof selfFieldComponent !== 'undefined') {
      FieldComponent = selfFieldComponent
    } else {
      switch (field.type) {
        case 'select':
        case 'multiSelect':
          FieldComponent = MutationFormSelect
          break
        case 'treeSelect':
          FieldComponent = MutationFormTreeSelect
          break
        case 'textarea':
          FieldComponent = MutationFormTextArea
          break
        case 'number':
          if (renderAsInput) {
            FieldComponent = MutationFormInput
            fieldProps.type = 'number'
          } else {
            FieldComponent = MutationFormInputNumber
          }
          break
        case 'image':
          FieldComponent = MutationFormImageUpload
          break
        case 'file':
          FieldComponent = MutationFormFileUpload
          break
        case 'checkbox':
          FieldComponent = MutationFormCheckbox
          break
        case 'switch':
          FieldComponent = MutationFormSwitch
          break
        case 'colour':
          FieldComponent = MutationFormColourPicker
          break
        case 'emailBody':
          FieldComponent = MutationFormEmailBody
          break
        case 'textWithSelect':
          FieldComponent = MutationFormInputWithSelect
          break
        case 'learnerAndGroupSelect':
          FieldComponent = MutationFormLearnerAndGroupSelect
          break
        case 'slate':
          FieldComponent = MutationFormSlate
          break
        case 'date':
        case 'time':
        case 'datetime':
          FieldComponent = MutationFormDateTimePicker
          break
        case 'dateRange':
          FieldComponent = MutationFormDateRangePicker
          break
        case 'radio':
          FieldComponent = MutationFormRadio
          break
        case 'timeRange':
          FieldComponent = MutationFormTimeRange
          break
        case 'custom':
          FieldComponent = selfFieldComponent || null
          break
        case 'unlayer':
          FieldComponent = MutationFormUnlayer
          break
        case 'textTags':
          FieldComponent = MutationFormTextTags
          break
        case 'timezone':
          FieldComponent = MutationFormTimezone
          break
        case 'collapse':
          FieldComponent = MutationFormCollapse
          break
        default:
          FieldComponent = MutationFormInput
          break
      }
    }
    switch (field.type) {
      case 'select':
      case 'multiSelect':
        if (fieldProps.linkField) {
          fieldProps.linkFieldValue = values[linkField]
        }
        break
      case 'checkbox':
      case 'switch':
        fieldProps.checked = fieldProps.value
        break
      case 'textWithSelect':
        fieldProps.input.value = values[field.input.id]
        fieldProps.select.value = values[field.select.id]
        fieldProps.input.errors = errors[field.input.id]
        fieldProps.select.errors = errors[field.select.id]
        break
      case 'collapse':
        fieldProps.fieldComponents = field.fields.map(field => this.renderField(field))
        break
      case 'slate':
        fieldProps.editorEndRef = this.editorEndRef
        break
      default:
        break
    }

    return (
      <FieldComponent key={`field-${field.id}`} {...fieldProps} ref={this.fieldRefs[field.id]} />
    )
  }

  render () {
    const { fields, activeTab, tabs: allTabs, updateActiveTab, values, forceTabRender = false, scrollContainer: ScrollContainer = null, errors } = this.props
    let renderedFields = null
    const tabs = allTabs.filter(({ visible }) => _isFunction(visible) ? visible(values) : (_isBoolean(visible) ? visible : true))
    if (tabs.length > 1) {
      const firstTab = tabs[0].id
      const fieldsByTab = fields.reduce((obj, field) => {
        const { tab = firstTab } = field
        obj[tab] = obj[tab] || []
        obj[tab].push(field)
        return obj
      }, {})

      renderedFields = (
        <Tabs activeKey={activeTab || firstTab} animated={false} onChange={updateActiveTab}>
          {
            tabs.reduce((acc, { id, title, disabled }) => {
              const tabFields = (fieldsByTab[id] || [])
              const renderedTabFields = tabFields.map(field => this.renderField(field))
              if (tabFields.length > 0) {
                let tabHead = title
                const tabDisabled = (_isFunction(disabled)
                  ? disabled(values)
                  : (_isBoolean(disabled)
                    ? disabled
                    : tabFields.every(field => this.isFieldDisabled(field) || !this.isFieldVisible(field))
                  )
                )
                const tabHasErrors = tabFields.some(field => errors[field.id]?.length > 0)
                if (tabHasErrors) {
                  tabHead = <span><Icon type='warning' />{title}</span>
                }

                acc.push(
                  <TabPane
                    tab={tabHead} key={id}
                    disabled={tabDisabled}
                    forceRender={forceTabRender}
                  >
                    {ScrollContainer
                      ? <ScrollContainer isTab>{renderedTabFields}</ScrollContainer>
                      : renderedTabFields}
                  </TabPane>
                )
              }
              return acc
            }, [])
          }
        </Tabs>
      )
    } else {
      renderedFields = fields.map(field => this.renderField(field))
      if (ScrollContainer) renderedFields = <ScrollContainer>{renderedFields}</ScrollContainer>
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        {renderedFields}
        <EditorEnd ref={this.editorEndRef} component={this.renderFooter()} />
      </Form>
    )
  }
}

MutationFormElement.propTypes = {
  disableSubmitIfInvalid: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.object),
  tabs: PropTypes.arrayOf(PropTypes.object),
  footer: PropTypes.func,
  footerAlign: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  btnBlock: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  disableSubmitOnEnter: PropTypes.bool,
  disabled: PropTypes.bool
}

MutationFormElement.defaultProps = {
  disableSubmitIfInvalid: true,
  fields: [],
  tabs: [],
  footer: null,
  footerAlign: 'center',
  onChange: () => {},
  onSubmit: () => {},
  get submitLabel () { return I18n.t('submitLabel', trOpt) },
  values: {},
  errors: {},
  disableSubmitOnEnter: false,
  btnBlock: false,
  disabled: false
}

export default MutationFormElement
