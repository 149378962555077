import React, { useMemo, useRef } from 'react'
import I18n from 'i18n-js'
import _pick from 'lodash/pick'

import { connect } from '../../hocs'
import selectors from '../../state/selectors'
import { dateTimeColumnSorter, numberColumnSorter, textColumnSorter, idArrayColumnSorter } from '../../helpers/listPages'
import { formatDate } from '../../helpers/datetime'
import { ActionTable } from '../common'
import { sortDataClasses, dataClassFilter, resolvedFilter, severityFilter } from '../../helpers/uBreachV2'
import SeverityField from './SeverityField'
import { SEVERITY_OPTIONS } from '../../constants/uBreach'
import UserBreachDetailsModal from '../Modals/uBreach/UserBreachDetailsModal'
import BreachDetailsModal from '../Modals/uBreach/BreachDetailsModal'
import DataClassTag from './DataClassTag'

const trOpt = { scope: 'uBreach' }

export const renderDataClasses = (dataClasses) => sortDataClasses(dataClasses).map(
  dataClass => <DataClassTag key={dataClass} dataClass={dataClass} />
)

const BreachesTable = ({
  isLearner,
  breaches,
  loading = false,
  showActions = true,
  performAction = () => {},
  tableProps = {},
  storageId = null,
  updateSelectedIds,
  selectedIds,
  uBreachProEnabled,
  breachedServices,
  breachedUsers,
  domain,
  error,
  refetchQueries,
  handleRefreshClick,
  showMultiSelectActions
}) => {
  const breachDetailsModalRef = useRef(null)
  const userBreachDetailsModalRef = useRef(null)

  const columns = useMemo(() => {
    const columns = [
      {
        title: I18n.t('tables.breachName', trOpt),
        dataIndex: 'breachTitle',
        key: 'breachTitle',
        sorter: (a, b) => textColumnSorter('breachTitle', a, b),
        triggerModal: (record) => {
          if (!isLearner) return breachDetailsModalRef
          return uBreachProEnabled && record.hasExposedInfoData ? userBreachDetailsModalRef : null
        },
        triggerModalAction: isLearner ? { key: 'view', label: I18n.t('common.view') } : null,
        modalRecordKey: isLearner ? 'breachId' : 'id',
        additionalModalArgs: !isLearner ? { domain } : {},
        useModalPager: true,
        defaultSortOrder: 'ascend'
      },
      {
        title: I18n.t('tables.severity', trOpt),
        dataIndex: 'severity',
        key: 'severity',
        sorter: (a, b) => idArrayColumnSorter('severity', SEVERITY_OPTIONS, a, b),
        render: value => <SeverityField severity={value} />,
        filters: severityFilter(breaches),
        filterMultiple: true,
        onFilter: (value, record) => record.severity === value
      },
      {
        title: I18n.t('breachDate', trOpt),
        dataIndex: 'breachDate',
        key: 'breachDate',
        sorter: (a, b) => dateTimeColumnSorter('breachDate', a, b),
        render: (breachDate) => breachDate ? formatDate(breachDate) : ''
      }
    ]
    if (isLearner && uBreachProEnabled) {
      columns.push(
        {
          title: I18n.t('tables.resolvedDate', trOpt),
          dataIndex: 'resolved',
          key: 'resolved',
          sorter: (a, b) => dateTimeColumnSorter('resolved', a, b),
          filters: resolvedFilter(breaches),
          filterMultiple: true,
          onFilter: (value, record) => value ? record.resolved !== null : record.resolved === null,
          render: (resolved) => resolved ? formatDate(resolved) : ''
        }
      )
    }
    if (!isLearner) {
      columns.push(
        {
          title: I18n.t('breachCount', trOpt),
          dataIndex: 'breachCount',
          key: 'breachCount',
          sorter: (a, b) => numberColumnSorter('breachCount', a, b)
        }
      )
      if (uBreachProEnabled) {
        columns.push(
          {
            title: I18n.t('tables.resolvedCount', trOpt),
            dataIndex: 'resolvedCount',
            key: 'resolvedCount',
            sorter: (a, b) => numberColumnSorter('resolvedCount', a, b)
          }
        )
      }
    }
    columns.push({
      title: I18n.t('exposedInformation', trOpt),
      dataIndex: 'dataClasses',
      key: 'dataClasses',
      render: value => renderDataClasses(value),
      width: 400,
      filters: dataClassFilter(breaches),
      filterMultiple: true,
      onFilter: (value, record) => record.dataClasses.includes(value)
    })
    return columns
  }, [isLearner, breaches, uBreachProEnabled, domain])

  const sortedBreaches = useMemo(() => breaches.sort((a, b) => {
    if (a.breachTitle < b.breachTitle) return -1
    if (a.breachTitle > b.breachTitle) return 1
    return 0
  }), [breaches])

  return (
    <>
      <ActionTable
        {...{
          columns,
          loading,
          performAction,
          showActions,
          tableProps,
          storageId,
          updateSelectedIds,
          showMultiSelectActions
        }}
        dataSource={sortedBreaches}
        buttonActionKeys={['view', 'markResolved']}
        // Only render the selections column if uBreachProEnabled, no need to render it otherwise
        selectedIds={uBreachProEnabled ? selectedIds : null}
      />
      {
        isLearner
          ? (uBreachProEnabled ? <UserBreachDetailsModal ref={userBreachDetailsModalRef} /> : null)
          : <BreachDetailsModal ref={breachDetailsModalRef} {...{ error, breachedServices, breachedUsers, refetchQueries, domain, handleRefreshClick }} />
      }
    </>
  )
}

export default connect(
  state => ({
    ..._pick(selectors.settings.get(state), ['uBreachProEnabled'])
  })
)(BreachesTable)
