export const permissions = {
  // Admin User permissions
  USER_CREATE: 'user.create',
  USER_READ: 'user.read',
  USER_UPDATE: 'user.update',
  USER_DELETE: 'user.delete',
  USER_LIST: 'user.list',

  USER_SUPER_CREATE: 'user.super.create',
  USER_SUPER_READ: 'user.super.read',
  USER_SUPER_UPDATE: 'user.super.update',
  USER_SUPER_LIST: 'user.super.list',

  // Settings permissions
  SETTINGS_GENERAL_READ: 'settings.general.read',
  SETTINGS_GENERAL_UPDATE: 'settings.general.update',

  SETTINGS_EMAIL_READ: 'settings.email.read',
  SETTINGS_EMAIL_UPDATE: 'settings.email.update',
  SETTINGS_EMAIL_TEST: 'settings.email.test',

  SETTINGS_ULEARN_READ: 'settings.ulearn.read',
  SETTINGS_ULEARN_UPDATE: 'settings.ulearn.update',

  SETTINGS_UPHISH_READ: 'settings.uphish.read',
  SETTINGS_UPHISH_UPDATE: 'settings.uphish.update',

  SETTINGS_UPOLICY_READ: 'settings.upolicy.read',
  SETTINGS_UPOLICY_UPDATE: 'settings.upolicy.update',

  SETTINGS_UBREACH_READ: 'settings.ubreach.read',
  SETTINGS_UBREACH_UPDATE: 'settings.ubreach.update',

  SETTINGS_SYNC_READ: 'settings.sync.read',
  SETTINGS_SYNC_UPDATE: 'settings.sync.update',

  SETTINGS_SSO_READ: 'settings.sso.read',
  SETTINGS_SSO_UPDATE: 'settings.sso.update',

  SETTINGS_RISK_REPORT_READ: 'settings.riskReport.read',
  SETTINGS_RISK_REPORT_UPDATE: 'settings.riskReport.update',

  DEFAULT_TENANT_SETTINGS_READ: 'defaultSettings.read',
  DEFAULT_TENANT_SETTINGS_UPDATE: 'defaultSettings.update',

  // Report permissions
  PERFORMANCE_REPORT_CREATE: 'performanceReport.create',
  PERFORMANCE_REPORT_READ: 'performanceReport.read',
  PERFORMANCE_REPORT_UPDATE: 'performanceReport.update',
  PERFORMANCE_REPORT_DELETE: 'performanceReport.delete',
  PERFORMANCE_REPORT_LIST: 'performanceReport.list',
  PERFORMANCE_REPORT_SEND: 'performanceReport.send',

  SCHEDULED_REPORT_CREATE: 'scheduledReport.create',
  SCHEDULED_REPORT_READ: 'scheduledReport.read',
  SCHEDULED_REPORT_UPDATE: 'scheduledReport.update',
  SCHEDULED_REPORT_DELETE: 'scheduledReport.delete',
  SCHEDULED_REPORT_LIST: 'scheduledReport.list',
  SCHEDULED_REPORT_SEND: 'scheduledReport.send',

  BREACH_REPORT_CREATE: 'breachReport.create',
  BREACH_REPORT_READ: 'breachReport.read',
  BREACH_REPORT_UPDATE: 'breachReport.update',
  BREACH_REPORT_DELETE: 'breachReport.delete',
  BREACH_REPORT_LIST: 'breachReport.list',
  BREACH_REPORT_SEND: 'breachReport.send',

  DOMAIN_SCAN_REPORT_CREATE: 'domainScanReport.create',

  RISK_REPORT_CREATE: 'riskReport.create',
  RISK_REPORT_UPDATE: 'riskReport.update',

  REPORT_ACCESS: 'report.access',

  // Learner permissions
  LEARNER_CREATE: 'learner.create',
  LEARNER_READ: 'learner.read',
  LEARNER_UPDATE: 'learner.update',
  LEARNER_DELETE: 'learner.delete',
  LEARNER_LIST: 'learner.list',
  LEARNER_RESTORE: 'learner.restore',
  LEARNER_BULK_CREATE: 'learner.bulkCreate',

  // Group permissions
  GROUP_CREATE: 'group.create',
  GROUP_READ: 'group.read',
  GROUP_UPDATE: 'group.update',
  GROUP_DELETE: 'group.delete',
  GROUP_LIST: 'group.list',

  // Course permissions
  COURSE_CREATE: 'course.create',
  COURSE_READ: 'course.read',
  COURSE_UPDATE: 'course.update',
  COURSE_DELETE: 'course.delete',
  COURSE_LIST: 'course.list',
  COURSE_REPORT: 'course.report',
  COURSE_DOWNLOAD_CERTIFICATE: 'course.downloadCertificate',
  COURSE_ACCESS_CONTROL: 'course.accessControl',

  COURSE_SUPER_CREATE: 'course.super.create',
  COURSE_SUPER_READ: 'course.super.read',
  COURSE_SUPER_UPDATE: 'course.super.update',
  COURSE_SUPER_DELETE: 'course.super.delete',
  COURSE_SUPER_LIST: 'course.super.list',
  COURSE_SUPER_ACCESS_CONTROL: 'course.super.accessControl',

  COURSE_ENROLMENT_CREATE: 'courseEnrolment.create',
  COURSE_ENROLMENT_READ: 'courseEnrolment.read',
  COURSE_ENROLMENT_UPDATE: 'courseEnrolment.update',
  COURSE_ENROLMENT_DELETE: 'courseEnrolment.delete',
  COURSE_ENROLMENT_LIST: 'courseEnrolment.list',
  COURSE_ENROLMENT_BULK_CREATE: 'courseEnrolment.bulkCreate',

  COURSE_REMINDER_SEND: 'courseReminder.send',

  // Breach permissions
  BREACH_READ: 'breach.read',
  BREACH_REPORT: 'breach.report',
  BREACH_LIST: 'breach.list',

  BREACH_RESOLUTION_CREATE: 'breachResolution.create',
  BREACH_RESOLUTION_READ: 'breachResolution.read',
  BREACH_RESOLUTION_LIST: 'breachResolution.list',

  // Policy permissions
  POLICY_CREATE: 'policy.create',
  POLICY_READ: 'policy.read',
  POLICY_UPDATE: 'policy.update',
  POLICY_DELETE: 'policy.delete',
  POLICY_LIST: 'policy.list',
  POLICY_REPORT: 'policy.report',

  POLICY_REMINDER_SEND: 'policyReminder.send',

  POLICY_TEMPLATE_CREATE: 'policyTemplate.create',
  POLICY_TEMPLATE_READ: 'policyTemplate.read',
  POLICY_TEMPLATE_UPDATE: 'policyTemplate.update',
  POLICY_TEMPLATE_DELETE: 'policyTemplate.delete',
  POLICY_TEMPLATE_LIST: 'policyTemplate.list',
  POLICY_TEMPLATE_ACCESS_CONTROL: 'policyTemplate.accessControl',

  POLICY_TEMPLATE_SUPER_CREATE: 'policyTemplate.super.create',
  POLICY_TEMPLATE_SUPER_READ: 'policyTemplate.super.read',
  POLICY_TEMPLATE_SUPER_UPDATE: 'policyTemplate.super.update',
  POLICY_TEMPLATE_SUPER_DELETE: 'policyTemplate.super.delete',
  POLICY_TEMPLATE_SUPER_LIST: 'policyTemplate.super.list',
  POLICY_TEMPLATE_SUPER_ACCESS_CONTROL: 'policyTemplate.super.accessControl',

  POLICY_RESULT_CREATE: 'policyResult.create',
  POLICY_RESULT_READ: 'policyResult.read',
  POLICY_RESULT_UPDATE: 'policyResult.update',
  POLICY_RESULT_DELETE: 'policyResult.delete',
  POLICY_RESULT_LIST: 'policyResult.list',

  // Simulation permissions
  SIMULATION_CREATE: 'simulation.create',
  SIMULATION_READ: 'simulation.read',
  SIMULATION_UPDATE: 'simulation.update',
  SIMULATION_DELETE: 'simulation.delete',
  SIMULATION_REPORT: 'simulation.report',
  SIMULATION_LIST: 'simulation.list',

  SIMULATION_RESULT_CREATE: 'simulationResult.create',
  SIMULATION_RESULT_READ: 'simulationResult.read',
  SIMULATION_RESULT_LIST: 'simulationResult.list',
  SIMULATION_EMAIL_TEST: 'simulation.emailTest',

  LANDING_PAGE_CREATE: 'landingPage.create',
  LANDING_PAGE_READ: 'landingPage.read',
  LANDING_PAGE_UPDATE: 'landingPage.update',
  LANDING_PAGE_DELETE: 'landingPage.delete',
  LANDING_PAGE_LIST: 'landingPage.list',
  LANDING_PAGE_ACCESS_CONTROL: 'landingPage.accessControl',

  LANDING_PAGE_SUPER_CREATE: 'landingPage.super.create',
  LANDING_PAGE_SUPER_READ: 'landingPage.super.read',
  LANDING_PAGE_SUPER_UPDATE: 'landingPage.super.update',
  LANDING_PAGE_SUPER_DELETE: 'landingPage.super.delete',
  LANDING_PAGE_SUPER_LIST: 'landingPage.super.list',
  LANDING_PAGE_SUPER_ACCESS_CONTROL: 'landingPage.super.accessControl',

  EMAIL_TEMPLATE_CREATE: 'emailTemplate.create',
  EMAIL_TEMPLATE_READ: 'emailTemplate.read',
  EMAIL_TEMPLATE_UPDATE: 'emailTemplate.update',
  EMAIL_TEMPLATE_DELETE: 'emailTemplate.delete',
  EMAIL_TEMPLATE_LIST: 'emailTemplate.list',
  EMAIL_TEMPLATE_ACCESS_CONTROL: 'emailTemplate.accessControl',
  EMAIL_TEMPLATE_EMAIL_TEST: 'emailTemplate.emailTest',

  EMAIL_TEMPLATE_SUPER_CREATE: 'emailTemplate.super.create',
  EMAIL_TEMPLATE_SUPER_READ: 'emailTemplate.super.read',
  EMAIL_TEMPLATE_SUPER_UPDATE: 'emailTemplate.super.update',
  EMAIL_TEMPLATE_SUPER_DELETE: 'emailTemplate.super.delete',
  EMAIL_TEMPLATE_SUPER_LIST: 'emailTemplate.super.list',
  EMAIL_TEMPLATE_SUPER_ACCESS_CONTROL: 'emailTemplate.super.accessControl',

  // uService permissions
  COMPANY_CREATE: 'company.create',
  COMPANY_READ: 'company.read',
  COMPANY_UPDATE: 'company.update',
  COMPANY_DELETE: 'company.delete',
  COMPANY_LIST: 'company.list',
  COMPANY_IMPERSONATE: 'company.impersonate',

  COMPANY_SUPER_CREATE: 'company.super.create',
  COMPANY_SUPER_READ: 'company.super.read',
  COMPANY_SUPER_UPDATE: 'company.super.update',
  COMPANY_SUPER_DELETE: 'company.super.delete',
  COMPANY_SUPER_LIST: 'company.super.list',
  COMPANY_SUPER_IMPERSONATE: 'company.super.impersonate',

  BILLING_READ: 'billing.read',
  BILLING_UPDATE: 'billing.update',

  // End user access permissions
  END_USER_ACCESS_FULL: 'endUser.access.full',
  END_USER_ACCESS_LIMITED: 'endUser.access.limited',

  // Usecure admin permissions
  USECURE_SUPER_ADMIN: 'usecure.super.admin' // Required for any usecure-wide admin actions (eg clearing caches)
}
