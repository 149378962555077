import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { Button, Card, message } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { generatePath, Link } from 'react-router-dom'
import compose from 'recompose/compose'
import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import I18n from 'i18n-js'

import LandingPageBuilderTable from '../../components/uPhish/LandingPageBuilderTable'
import { ContentWrap, ListHeader, ListHeaderPanel, SearchBar, ErrorAlerts, ListViewActions } from '../../components/common'
import { GET_SIMULATION_LANDING_PAGE_TEMPLATES } from '../../components/Queries/uPhish'
import routes from '../../constants/routes'
import DeleteLandingPageConfirm from '../../components/Modals/DeleteLandingPageConfirm'
import { connect, withConsumer } from '../../hocs'
import selectors from '../../state/selectors'
import { NONE_FILTER_VALUE } from '../../components/uPhish/TemplateHeader'
import { updateSelectedIdsOnRecordSetUpdate, processListActions } from '../../helpers/listPages'
import UpdateLandingPageLanguagesModal from '../../components/Modals/UpdateLandingPageLanguagesModal'
import { WRITABLE_LANDING_PAGE_BUILDER_ACTIONS } from '../../constants/actions'
import { CATEGORY_NAMES } from '../../constants/uPhish'
import IntercomHeader from '../../components/IntercomHeader'

import { useHasSessionPermission } from '../../hooks'
import { permissions } from '../../constants/permissions'
import { generateLandingPageLink } from '../../helpers/uPhish'
import useLocalStorage from '../../hooks/useLocalStorage'
import IncludeClientContentToggle from '../../components/common/IncludeClientContentToggle'
import { cleanUpContentCompanyNameListFilter } from '../../helpers/company'

const trOpt = { scope: 'uPhish.landingPageBuilder' }

const getActions = (record, disabledActions, omittedActions) => {
  const recordOmittedActions = [...omittedActions]
  if (!record.updateAllowed) recordOmittedActions.push('edit')
  if (!record.deleteAllowed) recordOmittedActions.push('delete')

  return processListActions({
    actions: [
      { key: 'edit', label: I18n.t('editLandingPage', trOpt), icon: 'edit' },
      { key: 'preview', label: I18n.t('previewLandingPage', trOpt), icon: 'eye' },
      { key: 'clone', label: I18n.t('uPhish.common.cloneLandingPage'), icon: 'copy' },
      { key: 'delete', label: I18n.t('deleteLandingPage', trOpt), icon: 'delete' }
    ],
    omittedActions: recordOmittedActions,
    disabledActions
  })
}

export const LandingPageView = ({
  loading = false, error,
  records: rawRecords = [],
  searchFilterText = '',
  updateSearchFilterText = () => { },
  panelLeft = [],
  panelRight = ['search', 'actions', 'add'],
  sorter,
  updateSorter,
  filters,
  updateFilters,
  pagination,
  updatePagination,
  history,
  refetchQueries,
  routeId,
  hasSuperPermission,
  companyId: userCompanyId,
  viewStorageId,
  planValid,
  includeClientContent,
  setIncludeClientContent
}) => {
  const [showDeleteModal, updateShowDeleteModal] = useState(false)
  const [activeId, setActiveId] = useState(null)
  const [activeIds, setActiveIds] = useState([])
  const [selectedIds, updateSelectedIds] = useState([])

  const onSearchChange = useCallback(event => {
    updateSearchFilterText(event.target.value)
  }, [updateSearchFilterText])

  const [allRecords, setAllRecords] = useState([])
  const [records, setRecords] = useState([])

  const { hasAllSessionPermissions } = useHasSessionPermission()

  const { disabledActions, omittedActions } = useMemo(() => {
    const omittedActions = []
    if (!hasAllSessionPermissions([permissions.LANDING_PAGE_CREATE])) {
      omittedActions.push('create', 'clone')
    }
    if (!hasAllSessionPermissions([permissions.LANDING_PAGE_UPDATE])) omittedActions.push('edit')
    if (!hasAllSessionPermissions([permissions.LANDING_PAGE_DELETE])) omittedActions.push('delete')

    return {
      disabledActions: !planValid ? WRITABLE_LANDING_PAGE_BUILDER_ACTIONS : null,
      omittedActions
    }
  }, [planValid, hasAllSessionPermissions])

  useEffect(() => {
    const allRecords = rawRecords.map((record) => {
      const { id, name, locales, companyId, category, global } = record
      return {
        id,
        key: id,
        name,
        locales,
        companyId: _get(record, 'company.id') || 'usecure',
        companyName: _get(record, 'company.name') || I18n.t('common.managedByUsecure'),
        isOwner: (hasSuperPermission || userCompanyId === companyId),
        actions: getActions(record, disabledActions, omittedActions),
        categoryValue: category || NONE_FILTER_VALUE,
        category: CATEGORY_NAMES[category] || CATEGORY_NAMES.none,
        global: global === true
      }
    })

    allRecords.sort((a, b) => a.name.localeCompare(b.name))

    setAllRecords(allRecords)
  }, [rawRecords, hasSuperPermission, userCompanyId, disabledActions, omittedActions])

  useEffect(() =>
    updateSelectedIdsOnRecordSetUpdate(updateSelectedIds, allRecords),
  [allRecords])

  const applyRecordFilters = useCallback(searchFilterText => {
    let records = [...allRecords]

    if (searchFilterText) {
      const filterFields = ['name']
      records = records.filter(record =>
        filterFields.some(field => {
          const value = record[field]
          return value && String(value).toLowerCase().includes(searchFilterText.toLowerCase())
        })
      )
    }

    setRecords(records)
  }, [allRecords])
  const debouncedApplyRecordFilters = useCallback(_debounce(applyRecordFilters, 500), [applyRecordFilters])
  useEffect(() => {
    debouncedApplyRecordFilters(searchFilterText)
  }, [debouncedApplyRecordFilters, searchFilterText])

  const updateActiveRecord = useCallback(ids => {
    ids = ids || []
    const [id] = ids
    setActiveId(id || null)
    setActiveIds(ids)
  }, [])
  const updateActiveId = useCallback(id => updateActiveRecord(id ? [id] : undefined), [updateActiveRecord])

  const openDeleteModal = useCallback(ids => {
    updateActiveRecord(ids)
    updateShowDeleteModal(true)
  }, [updateShowDeleteModal, updateActiveRecord])

  const afterModalClose = useCallback(() => updateActiveId(null), [updateActiveId])

  const openPreview = useCallback(templateId => {
    if (templateId && userCompanyId) {
      window.open(generateLandingPageLink({ landingPageId: templateId, companyId: userCompanyId }), '_blank')
    }
  }, [userCompanyId])

  const updateLanguagesModalRef = useRef(null)
  const openUpdateLanguagesModal = useCallback((ids, action) => {
    if (updateLanguagesModalRef.current) {
      updateLanguagesModalRef.current.open(ids, action)
    }
  }, [updateLanguagesModalRef])

  const performAction = useCallback(async (action, ids) => {
    updateActiveRecord(ids)

    switch (action) {
      case 'edit':
        history.push(generatePath(routes.UPHISH_LANDING_PAGE_UPDATE, { landing_page_id: ids[0] }))
        break
      case 'clone':
        history.push(generatePath(routes.UPHISH_LANDING_PAGE_CLONE, { landing_page_id: ids[0] }))
        break
      case 'delete':
        await openDeleteModal(ids)
        break
      case 'preview':
        openPreview(ids[0])
        break
      case 'addLanguages':
        openUpdateLanguagesModal(ids, 'add')
        break
      case 'removeLanguages':
        openUpdateLanguagesModal(ids, 'remove')
        break
      default:
        // This would appear if there was a bug
        message.error(I18n.t('common.actionCouldNotBePerformed'))
        break
    }
  }, [history, openDeleteModal, updateActiveRecord, openPreview, openUpdateLanguagesModal])

  const actions = useMemo(() => {
    let actions = []

    if (selectedIds.length === 1) {
      const record = rawRecords.find(({ id }) => id === selectedIds[0])
      if (record) {
        actions = getActions(record, disabledActions, omittedActions)
      }
    } else if (selectedIds.length > 1) {
      const selectedRecords = rawRecords.filter(({ id }) => selectedIds.includes(id))
      const selectedRecordsOmittedActions = [...omittedActions]

      if (selectedRecords.some(record => !record.updateAllowed)) selectedRecordsOmittedActions.push('addLanguages', 'removeLanguages')
      if (selectedRecords.some(record => !record.deleteAllowed)) selectedRecordsOmittedActions.push('delete')

      return processListActions({
        actions: [
          { key: 'delete', label: I18n.t('deleteLandingPages', trOpt), icon: 'delete' },
          { key: 'addLanguages', label: I18n.t('common.addLanguages'), icon: 'global', theme: 'outlined' },
          { key: 'removeLanguages', label: I18n.t('common.removeLanguages'), icon: 'global', theme: 'outlined' }
        ],
        omittedActions: selectedRecordsOmittedActions,
        disabledActions
      })
    }

    return actions
  }, [selectedIds, rawRecords, disabledActions, omittedActions])

  const renderHeaderPanel = useCallback((panelElems, align = 'left') => {
    return (
      <ListHeaderPanel align={align}>
        {
          panelElems.map((panelElem, index) => {
            switch (panelElem) {
              case 'search':
                return (
                  <SearchBar
                    key={index}
                    placeholder={I18n.t('searchForALandingPage', trOpt)}
                    value={searchFilterText}
                    allowClear
                    onChange={onSearchChange}
                    style={{ width: 200 }}
                  />
                )

              case 'actions':
                return (
                  <ListViewActions
                    key={index}
                    actions={actions} selectedIds={selectedIds}
                    performAction={performAction}
                    onEmptyActions='disable'
                  />
                )
              case 'add':
                return (
                  hasAllSessionPermissions([permissions.LANDING_PAGE_CREATE]) ? (
                    <Link to={routes.UPHISH_LANDING_PAGE_CREATE} key={index}>
                      <Button disabled={!planValid} type='primary' icon='plus'>{I18n.t('uPhish.common.createLandingPage')}</Button>
                    </Link>
                  ) : null
                )
              case 'include-client-content-toggle':
                return (
                  <IncludeClientContentToggle
                    key={index}
                    {...{ includeClientContent, setIncludeClientContent }}
                  />
                )
              default:
                return (
                  <div key={index}>{panelElem}</div>
                )
            }
          })
        }
      </ListHeaderPanel>
    )
  }, [
    searchFilterText, onSearchChange, performAction, selectedIds, actions, planValid, hasAllSessionPermissions,
    includeClientContent, setIncludeClientContent
  ])

  const panelLeftComponent = useMemo(() => renderHeaderPanel(panelLeft), [renderHeaderPanel, panelLeft])
  const panelRightComponent = useMemo(() => renderHeaderPanel(panelRight, 'right'), [renderHeaderPanel, panelRight])

  return (
    <>
      <ListHeader>
        {panelLeftComponent}
        {panelRightComponent}
        <>
          <DeleteLandingPageConfirm
            ids={activeIds}
            {...{ refetchQueries }}
            visible={showDeleteModal} setVisible={updateShowDeleteModal}
            afterClose={afterModalClose}
          />
          <UpdateLandingPageLanguagesModal
            ref={updateLanguagesModalRef}
            {...{ refetchQueries }}
            afterClose={afterModalClose}
          />
        </>
      </ListHeader>
      {
        error
          ? <ErrorAlerts {...{ error }} defaultError={I18n.t('yourLandingPagesCouldNotBeLoaded', trOpt)} />
          : (
            <LandingPageBuilderTable
              {...{
                loading,
                records,
                sorter,
                updateSorter,
                filters,
                updateFilters,
                pagination,
                updatePagination,
                selectedIds,
                updateSelectedIds,
                hasSuperPermission,
                performAction,
                activeId,
                updateActiveId,
                routeId,
                history,
                viewStorageId
              }}
            />
          )
      }
    </>
  )
}

const LandingBuilder = ({ history, userId, companyId, match, planValid }) => {
  const { hasAllSessionPermissions } = useHasSessionPermission()
  const hasSuperPermission = hasAllSessionPermissions([permissions.LANDING_PAGE_SUPER_LIST, permissions.LANDING_PAGE_SUPER_READ])
  const routeId = _get(match, 'params.landing_page_id')

  const [pagination, updatePagination] = useState(undefined)
  const [sorter, updateSorter] = useState(null)
  const [searchFilterText, updateSearchFilterText] = useState('')
  const [filters, updateFilters] = useState(hasSuperPermission ? { companyName: ['usecure'] } : null)
  const [includeClientContent, setIncludeClientContent] = useState(false)

  // Use localStorage to cache pagination and filters
  const viewStorageId = 'uPhishLandingPageBuilder'
  const storageId = useMemo(() => viewStorageId ? `${viewStorageId}|${companyId}|${userId}` : null, [viewStorageId, userId, companyId])
  const { localStorageInitialised, updateFromLocalStorage, updateLocalStorage } = useLocalStorage({ storageId })
  useEffect(() => {
    if (!updateFromLocalStorage) return
    updateFromLocalStorage({
      searchFilterText: updateSearchFilterText,
      filters: updateFilters,
      sorter: updateSorter,
      pagination: updatePagination
    })
  }, [updateFromLocalStorage, storageId])
  useEffect(() => {
    updateLocalStorage({
      searchFilterText,
      filters,
      sorter,
      pagination
    })
  }, [updateLocalStorage, searchFilterText, filters, sorter, pagination])

  const query = GET_SIMULATION_LANDING_PAGE_TEMPLATES
  const variables = {
    restrictToOwn: !hasSuperPermission || !includeClientContent,
    forUpdate: true,
    includeGlobal: hasSuperPermission
  }
  const { loading, error, data } = useQuery(query, { variables })

  const records = _get(data, 'simulationLandingPages', [])

  // Remove values that aren't in the template dataset from companyName filter
  useEffect(() => {
    cleanUpContentCompanyNameListFilter({
      records: data?.simulationLandingPages, filters, updateFilters, includeClientContent, hasSuperPermission, localStorageInitialised
    })
  }, [data, filters, includeClientContent, hasSuperPermission, localStorageInitialised])

  return (
    <ContentWrap>
      <Card>
        <IntercomHeader Size='h1' id='uPhish-landing-page-builder-header'>{I18n.t('common.uPhish')} - {I18n.t('landingPageBuilder', trOpt)}</IntercomHeader>
        <LandingPageView
          {...{
            loading,
            error,
            records,
            history,
            searchFilterText,
            updateSearchFilterText,
            sorter,
            updateSorter,
            pagination,
            updatePagination,
            filters,
            updateFilters,
            routeId,
            hasSuperPermission,
            companyId,
            viewStorageId,
            planValid,
            includeClientContent,
            setIncludeClientContent
          }}
          refetchQueries={[{ query, variables }]}
          panelLeft={hasSuperPermission ? ['include-client-content-toggle'] : []}
          panelRight={['search', 'actions', 'add']}
        />
      </Card>
    </ContentWrap>
  )
}

export default compose(
  withConsumer,
  connect(
    state => _pick(selectors.session.get(state), ['userId', 'companyId', 'planValid'])
  )
)(LandingBuilder)
